import DashboardLayout from "@/layout/DashboardLayout";
import Portfolio from "../views/programme_admin/Portfolio.vue";
import Portfolios from "../views/programme_admin/Portfolios.vue";
import Invitation from "../views/programme_admin/Invitation.vue";
import Requests from "../views/programme_admin/Requests.vue";
import Invitations from "../views/programme_admin/Invitations.vue";
import Centres from "../views/programme_admin/Centres.vue";
import Verifications from "../views/programme_admin/Verifications.vue";


export default {
    path: "/programme-admin/",
    component: DashboardLayout,
    children: [
        {
            path: "invite/accept/:token",
            name: "programme-admin-invitation",
            components: { default: Invitation },
        },
        {
            path: "portfolio/:id",
            name: "programme-admin-portfolio",
            components: { default: Portfolio },
        },
        {
            path: "portfolios",
            name: "programme-admin-portfolios",
            components: { default: Portfolios },
        },
        {
            path: "requests",
            name: "programme-admin-requests",
            components: { default: Requests },
        },
        {
            path: "exvitations",
            name: "programme-admin-exvitations",
            components: { default: Invitations },
        },
        {
            path: "centres",
            name: "programme-admin-centres",
            components: { default: Centres },
        },
        {
            path: "Verifications",
            name: "programme-admin-verifications",
            components: { default: Verifications },
        },
    ]
}