import DashboardLayout from "@/layout/DashboardLayout";
import Portfolio from "../views/assessor/Portfolio.vue";
import Portfolios from "../views/assessor/Portfolios.vue";
import Requests from "../views/assessor/Requests.vue";
import Invitations from "../views/assessor/Invitations.vue";
import Assessments from "../views/assessor/Assessments.vue";


export default {
    path: "/assessor/",
    component: DashboardLayout,
    children: [
        {
            path: "portfolio/:id",
            name: "assessor-portfolio",
            components: { default: Portfolio },
        },
        {
            path: "portfolios",
            name: "assessor-portfolios",
            components: { default: Portfolios },
        },
        {
            path: "requests",
            name: "assessor-requests",
            components: { default: Requests },
        },
        {
            path: "invitations",
            name: "assessor-invitations",
            components: { default: Invitations },
        },
        {
            path: "assessments",
            name: "assessor-assessments",
            components: { default: Assessments },
        },
    ]
}