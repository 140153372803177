<template>
<div>
  <base-header class="header pb-8 pt-5 pt-md-6 d-flex align-items-center" style="
        background-image: url(/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
    <!-- Mask -->
    <span class="mask bg-gradient-default opacity-95"></span>
    <!-- Header container -->
    <div class="container-fluid d-flex align-items-center mt-5">
      <div class="row">
        <div class="col-lg-7 col-md-10">
          <p class="text-white mt-0 mb-3">
            <!-- This is your profile page. You can see the progress you've made
              with your work and manage your projects or assigned tasks -->
          </p>
        </div>
      </div>
    </div>
  </base-header>
  <div class="container-fluid mt--7">
    <modal v-model:show="failedNetworkModal" body-classes="p-0" modal-classes="modal-dialog-centered">
      <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
        <template v-slot:header>
          <div class="text-muted text-left mb--5">
            <h2>Failed Requests</h2>
          </div>
        </template>
        <div class="text-center">

          <h3>
            Sorry, there seems to be an issue with your network!
          </h3>

        </div>
        <template v-slot:footer>
          <div class="">
            <base-button type="default" @click="reload()" class="my-1 float-right">
              Retry
            </base-button>
          </div>
        </template>
      </card>
    </modal>
    <modal v-model:show="showPortfolioModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
      <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
        <template v-slot:header>
          <div class="text-muted text-left mb--5">
            <h2>{{model.id? 'Edit' : 'Create'}} Assessment Portfolio</h2>
          </div>
        </template>
        <form role="form" @submit.prevent="addPortfolio();showPortfolioModal = false;">
          <div class="">
            <div class="row">
              <div class="col-sm-12">
                <multiple-select :required="true" :options="programmes" v-model:values="model.programme" :single="true" label="Programme" @update:values="getCentres();"></multiple-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <multiple-select :required="true" v-if="model.programme != undefined" :options="centres" v-model:values="model.centre" :single="true" label="Centre"></multiple-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <multiple-select :required="true" v-if="model.programme != undefined" :options="getProgramme(model.programme).courses.map(course => ({text: course.title, value: course.id}))" v-model:values="model.courses" :max="getProgramme(model.programme).coursesLimit" label="Courses"></multiple-select>
              </div>
            </div>
            <button class="d-none" ref="portfolioFormSubmitButton" type="submit"></button>
          </div>
        </form>
        <template v-slot:footer>
          <div class="">
            <base-button type="default" @click="$refs.portfolioFormSubmitButton.click()" class="my-1 float-right">
              Create
            </base-button>
          </div>
        </template>
      </card>
    </modal>
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card shadow type="secondary" class="mb-3">
          <template v-slot:header>
            <div class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-sm-8">
                  <h3 class="mb-0"><strong>Portfolio</strong></h3>
                </div>
                <div class="col-sm-4">
                  <base-button :loading="loading" size="sm" @click="showPortfolioModal = true" class="float-right">
                    <i class="fa fa-plus"></i>
                    Create Assessment Portfolio
                  </base-button>
                </div>
              </div>
            </div>
          </template>

          <div class="row">
            <div class="col-lg-12 mb-4" v-for="(portfolio, index) in portfolios" :key="index">
              <div class="card shadow">
                <div class="card-header">
                  <h5 class="heading-small col-7 col-sm-11 text-bold my--2">
                    <strong> Programme: {{ portfolio.programmeTitle }} </strong>
                  </h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="pl-2 mt--2">
                        <div class="row mb-2">
                          <div class="col-md-12">
                          </div>
                          <div class="col-md-12">
                            <p>
                              <b>Centre: </b> {{ portfolio.centreName }} <br />
                              <b>Location: </b> {{ portfolio.centre.address }}
                            </p>
                          </div>

                          <div class="col-12 mt-3 text-small">
                            <p> <b>Course: </b> {{ portfolio.courseTitle }}</p>
                          </div>
                          <div class="col-12 mt-2">
                            <!-- <strong class="heading-small">Delivery Time: {{ portfolio.deliveryTime[0]%31 + " - " + portfolio.deliveryTime[1]%31 }} days {{(portfolio.deliveryTime[1] == 30 ? "(or more)" : "") }} </strong> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <router-link class="card-footer text-white btn btn-lg bg-default" :to="{ name: 'trainee-portfolio', params: { id: portfolio.id, portfolio } }">
                  View Portfolio
                </router-link>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>

</div>
</template>

<script>

import swal from "sweetalert2";

import MultipleSelect from '../../components/MultipleSelect'
import PortfolioService from "@/api/services/portfolio.service";
import ProgrammeService from "@/api/services/programme.service";
import CentreService from "@/api/services/centre.service";
import UtilService from "../../api/services/util.service";

export default {
  components: {
    MultipleSelect
  },
  name: "portfolios",
  data() {
    return {
      _programmes: new Set(),
      _centres: new Set(),
      programmes: [],
      centres: [],
      portfolios: [],
      loading: false,
      failedNetworkModal: false,
      showPortfolioModal: false,
      model: {
        courses: [],
      },
    };
  },
  methods: {

    loadPortfolios() {
      PortfolioService.getPortfolios().then((response) => {

        this.portfolios = response.data.result;

      }).catch(() => {
        this.failedNetworkModal = true;
      })
    },

    getCentre(id) {
      for (const centre of this._centres) {
        if (centre.id == id) {
          return centre;
        }
      }
    },

    getCentres() {
      if (this.model.programme) {
        CentreService.getCentresByProgramme(this.model.programme).then((response) => {
          this.centres = response.data.result.map((centre) => ({
            value: centre.id,
            text: centre.name,
          }));
          response.data.result.forEach((centre) => this._centres.add(centre));
        }).catch(() => {});
      }
    },

    getProgramme(id) {
      for (const programme of this._programmes) {
        if (programme.id == id) {
          return programme;
        }
      }
    },

    addPortfolio() {
      this.loading = true;

      const addSwal = swal.mixin({
        customClass: {
          confirmButton: "btn btn-default btn-sm btn-fill",
        },
        buttonsStyling: false,
      });

      PortfolioService.addPortfolio(this.model).then((response) => {
        this.loadPortfolios();
        addSwal.fire({
          text: response.data.message,
          confirmButtonText: "Ok",
        });
      }).catch((error) => {
        addSwal.fire({
          text: error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message,
          confirmButtonText: "Ok",
        });
      }).finally(() => {
        this.loading = false;
      })
    },

    reload() {
      window.location.reload();
    }
  },
  computed: {

  },
  created() {

    //        UtilService.getCentres().then((response) => {
    //            this.centres = response.data;
    //        }).catch(() => {
    //            this.failedNetworkModal = true;
    //        })

    //        UtilService.getProgrammes().then((response) => {
    //            this.programmes = response.data;
    //        }).catch(() => {
    //            this.failedNetworkModal = true;
    //        })
  },
  mounted() {
    this.loadPortfolios();

    ProgrammeService.getProgrammes().then((response) => {
      this.programmes = response.data.result.map((programme) => ({
        value: programme.id,
        text: programme.title,
      }));
      response.data.result.forEach((programme) => this._programmes.add(programme));
    }).catch(() => {});
  },
};
</script>

<style></style>
