<template>
<div>
  <base-header class="header pb-8 pt-5 pt-md-6 d-flex align-items-center" style="
        background-image: url(/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
    <!-- Mask -->
    <span class="mask bg-gradient-default opacity-95"></span>
    <!-- Header container -->
    <div class="row my-5 pt-5">
      <router-link to="/centre/portfolios" class="col-xl-3 col-lg-6">
        <stats-card title="Trainees" type="gradient-seagreen" :sub-title="`${centre.traineeCount}`" icon="ni ni-hat-3" class="mb-4 mb-xl-0">
        </stats-card>
      </router-link>
      <router-link to="/centre/assessors" class="col-xl-3 col-lg-6">
        <stats-card title="Assessors" type="gradient-info" :sub-title="`${centre.assessorCount}`" icon="ni ni-badge" class="mb-4 mb-xl-0">
        </stats-card>
      </router-link>
      <router-link to="/centre/internal-verifiers" class="col-xl-3 col-lg-6">
        <stats-card title="Verifiers" type="gradient-purple" :sub-title="`${centre.internalVerifierCount}`" icon="ni ni-check-bold" class="mb-4 mb-xl-0">
        </stats-card>
      </router-link>
      <div class="col-xl-3 col-lg-6">
        <stats-card title="Assessments" type="gradient-indigo" :sub-title="`${centre.assessmentCount}`" icon="ni ni-bullet-list-67" class="mb-4 mb-xl-0">
        </stats-card>
      </div>
    </div>
  </base-header>
  <div class="container-fluid mt--7">

    <div class="row">
      <div class="col-xl-12 mb-5 mb-xl-0">
        <card type="primary" class="bg-gradient-dark" header-classes="bg-transparent">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">Trainee {{ bigLineChart.activeIndex === 0 ? "Registrations" : "Assessments"}}</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a class="nav-link py-2 px-3" href="#" :class="{ active: bigLineChart.activeIndex === 0 }" @click.prevent="initBigChart(0)">
                      <span class="d-none d-md-block">Registrations</span>
                      <span class="d-sm-none">R</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link py-2 px-3" href="#" :class="{ active: bigLineChart.activeIndex === 1 }" @click.prevent="initBigChart(1)">
                      <span class="d-none d-md-block">Assessments</span>
                      <span class="d-sm-none">A</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <canvas :height="350" :id="salesChartID"></canvas>
          </div>
        </card>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import Chart from "chart.js";

import FormMessage from '@/components/FormMessage'
import MultipleSelect from '@/components/MultipleSelect'
import CentreService from "@/api/services/centre.service";
import ProgrammeService from "@/api/services/programme.service";

let chart;

export default {
  components: {
    FormMessage,
    MultipleSelect,
  },
  name: "centres-by-admin",
  data() {
    return {
      profiles: [],
      failedNetworkModal: false,
      centre: {},
      model: {},
      edit: false,
      loading: true,
      programmes: [],
      states: [
        "ABIA",
        "ADAMAWA",
        "AKWA",
        "ANAMBRA",
        "BAUCHI",
        "BAYELSA",
        "BENUE",
        "BORNO",
        "CROSS",
        "DELTA",
        "EBONYI",
        "EDO",
        "EKITI",
        "ENUGU",
        "FCT",
        "GOMBE",
        "IMO",
        "JIGAWA",
        "KADUNA",
        "KANO",
        "KATSINA",
        "KEBBI",
        "KOGI",
        "KWARA",
        "LAGOS",
        "NASSARAWA",
        "NIGER",
        "OGUN",
        "ONDO",
        "OSUN",
        "OYO",
        "PLATEAU",
        "RIVERS",
        "SOKOTO",
        "TARABA",
        "YOBE",
        "ZAMFARA",
      ],
      formMessage: "",
      formMessageType: "",
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [
          [0,0,0,0,0,0,0,0,0,0,0,0],
          [0,0,0,0,0,0,0,0,0,0,0,0],
        ],
        activeIndex: 0,
      },
    };
  },
  methods: {
    reload() {
      window.location.reload();
    },

    initBigChart(index) {
      chart.destroy();
      chart = new Chart(
        document.getElementById(this.salesChartID).getContext("2d"), {
          type: "line",
          data: {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [{
              label:this.bigLineChart.activeIndex === 1 ? "Trainees" : "Assessments",
              tension: 0.4,
              borderWidth: 5,
              borderColor: "#77132b",
              borderStyle: "dashed",
              pointRadius: 0,
              backgroundColor: "transparent",
              data: this.bigLineChart.allData[index],
            }, ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(209,240,248,0.05)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 0,
                  fontColor: "#e8e8ea",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              }, ],
              xAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(209,240,248,0.0)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 10,
                  fontColor: "#e8e8ea",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              }, ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
      this.bigLineChart.activeIndex = index;
    },

    mountChart() {
      chart = new Chart(
        document.getElementById(this.salesChartID).getContext("2d"), {
          type: "line",
          data: {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [{
              label: this.bigLineChart.activeIndex === 1 ? "Trainees" : "Assessments",
              tension: 0.4,
              borderWidth: 5,
              borderColor: "#77132b",
              borderStyle: "dashed",
              pointRadius: 0,
              backgroundColor: "transparent",
              data: this.bigLineChart.allData[1],
            }, ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: true,
            },
            scales: {
              yAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(209,240,248,0.05)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 0,
                  fontColor: "#e8e8ea",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              }, ],
              xAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(209,240,248,0.0)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 10,
                  fontColor: "#e8e8ea",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              }, ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },

    handleEdit() {
      if (this.edit) {
        this.$refs.editButton.click()
      }
      this.edit = true;
    },

    saveEdit() {
      this.loading = true;
      CentreService.updateCentre(this.model).then((response) => {

        this.centre = response.data.result;
        this.model = this.centre;

        this.formMessage = response.message;
        this.formMessageType = "success";

      }).catch((error) => {
        this.formMessage = error.response == undefined ?
          "Something went wrong. <br> Please check your network and try again." : error.response.data.message;
        this.formMessageType = "danger";
      }).finally(() => {
        this.loading = false;
        this.edit = false;
      });
    },

  },

  mounted() {
    CentreService.getCentre().then((response) => {

      this.centre = response.data.result;
      this.model = {
        ...response.data.result,
        programmes: []
      };

      this.bigLineChart.allData[0] = Object.values(response.data.result.registrations);
      this.bigLineChart.allData[1] = Object.values(response.data.result.assessments);

      this.mountChart();

    }).catch(() => {
      this.failedNetworkModal = true;
    }).finally(() => {
      this.loading = false;
    })

    ProgrammeService.getProgrammes().then((response) => {
      this.programmes = response.data.result;
    }).catch(() => {
      this.failedNetworkModal = true;
    })
  },
};
</script>

<style></style>
