<template>
<div class="content">
  <modal v-model:show="showPhotoModal" body-classes="p-0" modal-classes="modal-dialog-centered">
    <card type="orange" shadow header-classes="pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
      <template v-slot:header>
        <div class="text-white text-left mb--5">
          <h3>{{assessor.name}}'s Profile Picture</h3>
        </div>
      </template>
      <form role="form" class="text-center">
        <label class="img-overlay-wrapper" style="border-radius:100%;">
          <img :src="user.photoUrl == null ? '/img/theme/user.jpg' : user.photoUrl" class=" rounded-circle assessor-modal-img" />
        </label>
      </form>
      <template v-slot:footer>
        <div class="">
          <base-button type="warning" @click="showPhotoModal = false;" class="my-1 float-right">
            Close
          </base-button>
        </div>
      </template>
    </card>
  </modal>
  <base-header class="header pb-8 pt-5 pt-md-6 d-flex align-items-center" style="
        background-image: url(/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
    <!-- Mask -->
    <span class="mask bg-gradient-default opacity-95"></span>
    <!-- Header container -->
    <div class="container-fluid d-flex align-items-center mt-5">
      <div class="row">
        <div class="col-lg-7 col-md-10">
          <p class="text-white mt-0 mb-3">
            <!-- This is your profile page. You can see the progress you've made
              with your work and manage your projects or assigned tasks -->
          </p>
        </div>
      </div>
    </div>
    <div class="container-fluid my-5">
      <div class="row">
        <div class="col-xl-5 mt-5 mb-xl-0 ml-lg-4">
          <div class="card card-profile shadow  ">
            <div class="row justify-content-left">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#" class="image-overlay-wrapper">
                    <img @click="showPhotoModal = true" :src="assessor.photoUrl == null ? '/img/theme/user.jpg' : assessor.photoUrl" class=" rounded-circle assessor-img">
                  </a>
                </div>
              </div>
            </div>
            <div class="card-header text-center bg-transparent">
              <h2 class="mb-0 assessor-name">{{assessor.name}} </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 mt-2 mb-xl-0 ml-lg-4">
          <div class="card card-profile shadow mx-1 ">
            <div class="card-header p-1 px-2 bg-transparent">
              <div class="row">
                <span class="col ">
                  <strong class="mb-0">Programme</strong>
                </span>
                <span class="col-auto">
                  <strong class="mb-0 float-right">{{assessor.programmeTitle ? assessor.programmeTitle : ''}} </strong>
                </span>
              </div>
            </div>
          </div>
          <div class="card card-profile mt-2 mx-2 " style="border-radius: 0.375rem 0.375rem 20px 20px;">
            <div class="card-header p-1 px-2 bg-transparent">
              <div class="row">
                <span class="col-4 ">
                  <strong class="mb-0">Phone</strong>
                </span>
                <span class="col-8">
                  <strong class="mb-0 float-right">{{assessor.phone}} </strong>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </base-header>
  <div class="container-fluid mt--7">

    <div>
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <template v-slot:header>
          <div class="row align-items-center">
            <div class="col-sm-4 d-flex">
              <h3 class="mb-0">Trainee Portfolios</h3>
            </div>
            <div class="col-sm-8 text-right">
              <base-button :loading="loading" @click="loadTable" type="dark">
                <i class="fa fa-sync"> </i>
              </base-button>
            </div>
          </div>
        </template>
        <div class="px-3">
          <form-message :type="upload.messageType" v-model:message="upload.message"></form-message>
        </div>
        <div class="px-3">
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

            <div class="row col-sm-6">
              <div class="form-group col-sm-8 col-md-8 col-lg-5">
                <label class="form-control-label">Items Per Page</label>
                <select @change="loadTable" class="form-control form-control-alternative" v-model="pagination.perPage" placeholder="Per Page">
                  <option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                  </option>
                </select>
              </div>
            </div>

            <div class="my-3 mr-4 col-sm-6 col-lg-5">
              <div class="row">
                <div class="form-group col-12">
                  <label class="form-control-label">Select Centre</label>
                  <select @change="loadTable" class="form-control form-control-alternative" v-model="selectedCentre">
                    <option v-for="option in centres" :value="option.value">{{option.text}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <base-input type="search" class="col-10 " clearable placeholder="Search Logs & Evidences" v-model:value="searchQuery" input-classes="form-control form-control-alternative">
                </base-input>
                <div class="col-2">
                  <base-button type="default" @click="loadTable">
                    <i class="fa fa-search"></i>
                  </base-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loading" class="text-center">
            <div class="lds-grid">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <el-table v-show="!loading" :data="tableData" row-key="id" class="px-3" header-row-class-name="thead-light">
            <!-- <el-table-column label="Select">
                            <template v-slot:default="props">
                                <input class="ml-3" type="checkbox" v-model="selection" :value="props.row.id">
                            </template>
                        </el-table-column> -->
            <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column" sortable>
            </el-table-column>
            <el-table-column width="70" align="left" label="Photo">
              <template v-slot:default="props">
                <router-link :to="{ name: 'internal-verifier-portfolio', params: { id: props.row.id } }">
                  <img :src="props.row.photoUrl || '/img/theme/user.jpg'" style="border: 2px solid #77132b; border-radius: 50%; object-fit: cover; width: 50px !important; height: 50px !important;">
                </router-link>
              </template>
            </el-table-column>
            <el-table-column align="left" label="Trainee">
              <template v-slot:default="props">
                <div class="d-flex">
                  <router-link :to="{ name: 'internal-verifier-portfolio', params: { id: props.row.id } }">
                    <strong>{{props.row.name}}</strong>
                  </router-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="left" label="Phone">
              <template v-slot:default="props">
                <div class="d-flex">
                  <a :href="`tel:${props.row.phone}`">
                    <strong>{{props.row.phone}}</strong>
                  </a>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="left" label="Centre">
              <template v-slot:default="props">
                <div class="d-flex text-nowrap">
                  {{props.row.centre.name}}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="left" label="Course">
              <template v-slot:default="props">
                <div class="d-flex">
                  {{props.row.course.title}}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Assessment">
              <template v-slot:default="props">
                <div class="d-block text-center">
                  <router-link :to="{name: 'internal-verifier-portfolio', params: {id: props.row.id } }" :loading="props.row.loading" class="btn btn-sm" :class="`btn-${props.row.assessedItemsCount == 0?'danger':props.row.assessedItemsCount == props.row.itemsCount? 'success' : 'warning'}`">
                    {{ props.row.assessedItemsCount + ' of ' + props.row.itemsCount }}
                  </router-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template v-slot:footer>
          <div v-if="!loading" class="col-12  d-flex justify-content-center justify-content-sm-between flex-wrap">
            <!-- <div class="col-sm-6">
                            <base-button @click="handleDelete(props.$index, props.row)" class="like btn-link" type="danger" size="sm" icon>
                                        Delete 
                                    </base-button>
                        </div> -->
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} portfolios.
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model:value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total" @update:value="loadTable">
            </base-pagination>
          </div>
        </template>
      </card>
    </div>
  </div>

</div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import AssessorService from "@/api/services/assessor.service";
import PortfolioService from "@/api/services/portfolio.service";
import InternalVerifierService from "@/api/services/internal-verifier.service";
import BaseInput from '@/components/BaseInput.vue';
import FormMessage from "../../components/FormMessage.vue";
import BaseCheckbox from "../../components/BaseCheckbox.vue";

export default {
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    BaseInput,
    FormMessage,
    BaseCheckbox
  },
  data() {
    return {
      upload: {
        loading: false,
        message: "",
        messageType: ""
      },
      id: "",
      assessor: {},
      internalVerifier: {},
      centres: [],
      selectedCentre: null,
      showPhotoModal: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [1, 2, 3, 10, 25, 50, 100, 250, 500],
        total: 0,
      },
      loaded: false,
      loading: true,
      query: "",
      searchQuery: "",
      propsToSearch: ["title", "dataType", "centre"],
      tableColumns: [{
          prop: "title",
          label: "Activity Title",

        },
        {
          prop: "logDate",
          label: "Date",
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      logBook: {},
      assessment: {},

    };
  },
  computed: {
    pagedData() {
      return this.tableData;
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */

    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = (row[key] + "").toLowerCase().toString();
          if (
            rowValue.includes &&
            rowValue.includes(this.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.pagination.total < highBound) {
        highBound = this.pagination.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    user() {
      return JSON.parse(window.localStorage.getItem("auth")).user;
    },

  },
  methods: {
    async loadTable() {

      this.loading = true

      if (this.from > this.pagination.total) {
        this.pagination.currentPage = 1;
      }

      let params = this.id + "?size=" + this.pagination.perPage + "&page=" + (this.pagination.currentPage - 1);

      if (this.searchQuery.length > 0) {
        params = params + "&s=" + this.searchQuery;
      }

      var response = await PortfolioService.getPortfoliosByAssessorAndCentre(this.id, this.selectedCentre, params);

      this.loading = false;
      if (response.data.result != (null || undefined)) {
        this.tableData = response.data.result;
        this.pagination.total = response.data.totalElements
        this.pagination.perPage = response.data.size
        this.pagination.currentPage = response.data.page + 1
      }

      return this.tableData;
    },
  },
  created() {
    if (this.$route.params.id == undefined) {
      this.$router.push("/internal-verifier/assessors");
      // }else if(this.$route.params.assessor != undefined){
      //     this.logBook = this.$route.params.assessor;
    } else {
      this.id = this.$route.params.id;

      AssessorService.getAssessorById(this.id).then((response) => {
        this.assessor = response.data.result;

        InternalVerifierService.getProfileForProgramme(this.assessor.programmeId).then((response) => {
          this.internalVerifier = response.data.result;
        }).catch((error) => { });
      }).catch(() => {});
    }
  },
  mounted() {},

};
</script>

<style scoped>
.el-table {

  overflow-x: scroll;
}

.no-border-card .card-footer {
  border-top: 0;
  border-top: 0;
}

.modal-centre-img {
  border-radius: 100%;
  border: 4px solid rgb(187, 100, 121);
  width: 180px;
  height: 180px;
  object-fit: cover;
  box-shadow: 0px 3px 10px grey !important;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #77132b;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.assessor-img {
  border: 3px solid #fff;
  width: 180px;
  height: 180px;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background: #efefef;
}

.assessor-modal-img {
  border: 5px solid #fff;
  width: 280px;
  height: 280px;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background: #efefef;
}

.assessor-name {
  float: right;

}

.assessor-name {
  float: right;

}

@media (max-width: 990px) {
  .assessor-name {
    padding-top: 120px;
    float: none;
  }
}
</style>
