<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Daily Engagements By Course </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="tableData">
        <template v-slot:columns>
          <th>Course Title</th>
          <th>Trainees</th>
          <th>%</th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <strong>{{ row.item.courseTitle }}</strong>
          </th>
          <td>
            {{ row.item.traineeCount }}
          </td>
          <td>
            <i
              class="fas fa-arrow-up text-success mr-3"
              :class="
                row.item.bounceRateDirection === 'up'
                  ? 'text-success'
                  : 'text-danger'
              "
            >
            </i>
            {{ row.item.bounceRate }}
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-visits-table",
  data() {
    return {
      tableData: [
        {
          courseTitle: "Masonry",
          traineeCount: "69",
          unique: "340",
          bounceRate: "46,53%",
          bounceRateDirection: "up",
        },
        {
          courseTitle: "Carpentry",
          traineeCount: "58",
          unique: "340",
          bounceRate: "46,53%",
          bounceRateDirection: "up",
        },
        {
          courseTitle: "Plumbing",
          traineeCount: "43",
          unique: "340",
          bounceRate: "46,53%",
          bounceRateDirection: "up",
        },
        {
          courseTitle: "Tiling",
          traineeCount: "65",
          unique: "340",
          bounceRate: "46,53%",
          bounceRateDirection: "up",
        },
      ],
    };
  },
};
</script>
<style></style>
