<template>
<div class="row justify-content-center">
  <div class="col-lg-5 col-md-7">
    <div class="card bg-secondary shadow border-0">
      <div class="card-body px-lg-5 py-lg-5">
        <div class="text-center text-muted mb-4">
          <h4>{{requestSent ? 'Password reset link sent' : 'Enter you phone number or email'}}</h4>
          <small>You will receive a link to continue</small>
        </div>
        <div v-if="requestSent" class="text-center">
          Follow the link in your mail to reset your password.
        </div>
        <form v-else @submit.prevent="requestPasswordReset">
          <form-message v-model:message="message" v-model:type="messageType"></form-message>
          
          <base-input required pattern="[0][7-9][0-9]{9}|^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" inputClasses="text-center" formClasses="input-group-alternative mb-3" placeholder="Phone number or Email address" addon-left-icon="ni ni-mobile-button" addon-right-icon="ni ni-email-83" v-model:value="model.phone">
          </base-input>

          <button type="submit" ref="formButton" class="d-none"></button>

          <div class="text-center">
            <base-button @click="$refs.formButton.click()" :loading="loading" type="primary" class="my-4">Get Password Reset Link</base-button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <router-link to="/register">
          <strong class="text-dark text-sm">
            Create new account
          </strong>
        </router-link>
      </div>
      <div class="col-6 text-right">
        <router-link to="/login" class="text-dark">
          <strong class="text-dark text-sm">
            Login into your account
          </strong>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import AuthService from '@/api/services/auth.service'
import FormMessage from '@/components/FormMessage';

export default {
  name: "login",
  components: {
    FormMessage,
  },
  data() {
    return {
      model: {
        phone: "",
      },
      message: "",
      messageType: "",
      loading: false,
      requestSent: false,
    };
  },
  methods: {
    requestPasswordReset() {
      this.loading = true;
      AuthService.requestPasswordReset(this.model).then((response) => {

        this.message = response.data.message;
        this.messageType = "success";
        this.requestSent = true;

      }).catch((error) => {

        this.message = error.response == undefined ?
          "Something went wrong. <br> Please check your network and try again." : error.response.data.message;
        this.messageType = "danger";
        
      }).finally(() => {
        this.loading = false
      })
    }
  },
};
</script>

<style></style>
