import DashboardLayout from "@/layout/DashboardLayout";

import Dashboard from "../views/centre_admin/Dashboard.vue";
import Administrator from "../views/centre_admin/Administrator.vue";
import Portfolio from "../views/centre_admin/Portfolio.vue";
import Portfolios from "../views/centre_admin/Portfolios.vue";
import Assessor from "../views/centre_admin/Assessor.vue";
import Assessors from "../views/centre_admin/Assessors.vue";
import InternalVerifier from "../views/centre_admin/InternalVerifier.vue";
import InternalVerifiers from "../views/centre_admin/InternalVerifiers.vue";


export default {
    path: "/centre/",
    component: DashboardLayout,
    children: [
        {
            path: "dashboard",
            name: "centre-dashboard",
            components: { default: Dashboard },
        },
        {
            path: "administrator",
            name: "centre-administrator",
            components: { default: Administrator },
        },
        {
            path: "portfolios",
            name: "centre-portfolios",
            components: { default: Portfolios },
        },
        {
            path: "portfolio/:id",
            name: "centre-portfolio",
            components: { default: Portfolio },
        },
        {
            path: "assessors",
            name: "centre-assessors",
            components: { default: Assessors },
        },
        {
            path: "assessor/:id",
            name: "centre-assessor",
            components: { default: Assessor },
        },
        {
            path: "internal-verifiers",
            name: "centre-internal-verifiers",
            components: { default: InternalVerifiers },
        },
        {
            path: "assesinternal-verifiersor/:id",
            name: "centre-internal-verifier",
            components: { default: InternalVerifier },
        },

    ]
}