<template>
<div class="row text-center justify-content-center mb-5">
  <div v-show="!model.roleIsSet" class="col-12 col-md-8 col-lg-6 py-2 mb-5" style="height: 60vh">
    <div class="card bg-secondary shadow border-0 pb-5">
      <div class="card-header bg-transparent pb-2">
        <h3 class="pb-2">
          Welcome to E-limi
        </h3>
        <h6>
          Please select one of the roles below to begin registration.
        </h6>
      </div>

      <div class="card-body px-4 py-4">
        <div class="row">
          <div class="col-6 mb-3">
            <router-link class="card shadow bg-gradient-green bg-white py-2" :click="model.role='Trainee'" to="/register/trainee">
              <div class="shadow  bg-white text-dark mb-3 py-2">
                <button type="button" class="btn rounded-circle btn-icon-only btn-icon btn-dark btn-lg">
                  <i class="ni ni ni-user-run" style="font-size: 30px; "></i>
                </button>
              </div>
              <h2 class="btn-block bg-white py-2">
                <strong class="text-dark text-bold">Trainee</strong>
              </h2>
              <!-- <div class="py-1 bg-white"></div> -->
            </router-link>
          </div>
          <div class="col-6 mb-3">
            <router-link class="card shadow bg-gradient-teal bg-white py-2" :click="model.role='Assessor'" to="/register/assessor">              
              <div class="shadow  bg-white text-dark mb-3 py-2">
                <button type="button" class="btn rounded-circle btn-icon-only btn-icon btn-dark btn-lg">
                  <i class="ni ni-badge" style="font-size: 26px; "></i>
                </button>
              </div>
              <h2 class="btn-block bg-white py-2">
                <strong class="text-dark text-bold">Assessor</strong>
              </h2>
              <!-- <div class="py-1 bg-white"></div> -->
            </router-link>
          </div>
          <div class="col-6 mb-3">
            <router-link class="card shadow bg-gradient-purple bg-white py-2" :click="model.role='Internal Verifier'" to="/register/internal_verifier">
              <div class="shadow bg-white text-dark mb-3 py-2">
                <button type="button" class="btn rounded-circle btn-icon-only btn-icon btn-dark btn-lg">
                  <i class="ni ni-check-bold" style="font-size: 28px; "></i>
                </button>
              </div>
              <h2 class="btn-block bg-white py-2">
                <strong class="text-dark text-bold">Internal Verifier</strong>
              </h2>
              <!-- <div class="py-1 bg-white"></div> -->
            </router-link>
          </div>
          <div class="col-6 mb-3">
            <router-link class="card shadow bg-gradient-indigo bg-white py-2" :click="model.role='Centre'" to="/register/centre">
              <div class="shadow  bg-white text-dark mb-3 py-2">
                <button type="button" class="btn rounded-circle btn-icon-only btn-icon btn-dark btn-lg">
                  <i class="ni ni-building" style="font-size: 28px; "></i>
                </button>
              </div>
              <h2 class="btn-block bg-white py-2">
                <strong class="text-dark text-bold">Centre <br></strong>
              </h2>
              <!-- <div class="py-1 bg-white"></div> -->
            </router-link>
          </div>
          <div class="col-6 mb-3 d-none">
            <router-link class="card shadow bg-gradient-dark bg-white py-2" :click="model.role='External Verifier'" to="/register/external_verifier">
              <div class="shadow bg-white text-dark mb-3 py-2">
                <button type="button" class="btn rounded-circle btn-icon-only btn-icon btn-dark btn-lg">
                  <i class="ni ni-like-2" style="font-size: 28px; "></i>
                </button>
              </div>
              <h2 class="btn-block bg-white py-2">
                <strong class="text-dark text-bold">External Verifier</strong>
              </h2>
              <!-- <div class="py-1 bg-white"></div> -->
            </router-link>
          </div>
          <div class="col-6 mb-3 d-none">
            <router-link class="card shadow bg-gradient-white bg-white py-2" :click="model.role='Manager'" to="/register/administrator">
              <div class="shadow  bg-white text-dark mb-3 py-2">
                <button type="button" class="btn rounded-circle btn-icon-only btn-icon btn-dark btn-lg">
                  <i class="ni ni-atom" style="font-size: 28px; "></i>
                </button>
              </div>
              <h2 class="btn-block bg-white py-2">
                <strong class="text-dark text-bold">Administrator <br></strong>
              </h2>
              <!-- <div class="py-1 bg-white"></div> -->
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6 text-left">
        <router-link to="/forgot-password">
          <strong class="text-dark text-sm">
            Forgot Password?
          </strong>
        </router-link>
      </div>
      <div class="col-6 text-right">
        <router-link to="/login" class="text-dark">
          <strong class="text-dark text-sm">
            Login into your account
          </strong>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  passwordStrength
} from "check-password-strength";

export default {
  name: "Register",
  data() {
    return {
      model: {
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        role: false,
      },
      loading: false,
      successful: false,
      registerError: "",
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    passwordTest() {
      return passwordStrength(this.model.password);
    },

    roleIsSet() {
      ['Trainee', 'Centre'].includes(this.model.role);
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    testPassword() {
      this.passwordTest = passwordStrength(this.password);
      console.log(passwordStrength(this.password));
    },
    successFn(data) {
      if (data.accessToken != null) {
        window.localStorage.setItem("auth", JSON.stringify(data));
        this.$router.push("/profile");
      }
      this.loading = false;
    },
    failureFn(error) {
      this.registerError = error.response != undefined ? error.response.data.message : "Something went wrong. <br> Please check your network and try again.";
      this.loading = false;
    },
    handleRegister() {

      if (this.model.password != this.model.confirmPassword) {
        this.registerError = "Passwords do not match";
        return;
      }

      if (this.passwordTest.id < 1) {
        this.registerError = "Please use a Stronger Password";
        return;
      }

      this.loading = true;

      var obj = {
        model: this.model,
        success: this.successFn,
        failure: this.failureFn,
      };

      this.$store.dispatch("auth/register", obj);
    },
  },
};
</script>

<style></style>
