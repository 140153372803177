<template>
<div class="row justify-content-center">
  <div class="col-lg-5 col-md-7">
    <div class="card bg-secondary shadow border-0">
      <div class="card-header bg-transparent pb-5">
        <div class="text-default text-center mt-2">
          <h2 class="text-default">Register on the NSQ Portal</h2>
          <small><strong>Sign up with your {{role === 'trainee' ? 'phone number' : 'email address'}}.</strong></small>
        </div>
      </div>
      <div class="card-body px-lg-5 pb-lg-5 pt-1">
        <form role="form" @submit.prevent="handleRegister()">
          <div class="form-group mb-5 text-center">
            <router-link to="/register" class="btn form-control">
                {{String(role).toUpperCase().replace('_', ' ')}}
            </router-link>
            <h6 class="">
              Click above to go back and choose your role.
            </h6>
          </div>

          <form-message v-model:type="messageType" v-model:message="message"></form-message>

          <base-input :required="true" formClasses="input-group-alternative" placeholder="Firstname" addon-left-icon="ni ni-single-02" :disabled="loading" v-model:value="model.firstName">
          </base-input>

          <base-input :required="true" formClasses="input-group-alternative" placeholder="Lastname" addon-left-icon="ni ni-single-02" :disabled="loading" v-model:value="model.lastName">
          </base-input>

          <base-input :required="true" v-if="role !== 'trainee'" formClasses="input-group-alternative" placeholder="Email Address" type="email" addon-left-icon="ni ni-email-83" :disabled="loading" v-model:value="model.email">
          </base-input>

          <base-input :required="true" pattern="[0][7-9][0-9]{9}" formClasses="input-group-alternative" placeholder="Mobile Number" addon-left-icon="ni ni-mobile-button" :disabled="loading" v-model:value="model.phone">
          </base-input>

          <base-input :required="true" formClasses="input-group-alternative" placeholder="Password" type="password" addon-left-icon="ni ni-lock-circle-open" :disabled="loading" v-model:value="model.password">
          </base-input>

          <base-input :required="true" formClasses="input-group-alternative" placeholder="Confirm Password" type="password" addon-left-icon="ni ni-lock-circle-open" :disabled="loading" v-model:value="model.confirmPassword">
          </base-input>

          <div class="text-muted font-italic" v-if="model.password != ''">
            <small>Password Strength:
              <span class="font-weight-700" :class="passwordTest.id < 2
                                  ? 'text-danger'
                                  : passwordTest.id == 2
                                  ? 'text-info'
                                  : 'text-success'">
                {{ passwordTest.value }}
              </span></small>
          </div>

          <div class="text-muted font-italic" v-show="model.confirmPassword != '' && model.password != model.confirmPassword">
            <small>
              <span class="text-danger font-weight-700">
                Passwords do not match!
              </span></small>
          </div>

          <div class="row my-4">
            <div class="col-12">
              <base-checkbox :required="true" class="custom-control-alternative">
                <span class="text-muted">I agree with the
                  <a href="#" target="_blank">Privacy Policy</a>
                </span>
              </base-checkbox>
            </div>
          </div>
          <div class="text-center">
            <base-button :loading="loading" native-type="submit" type="default" class="my-4">
              Create Account</base-button>
          </div>
        </form>
      </div>
    </div>
    
    <div class="row mt-2">
      <div class="col-6">
        <router-link to="/forgot-password">
          <strong class="text-dark text-sm">
            Forgot Password?
          </strong>
        </router-link>
      </div>
      <div class="col-6 text-right">
        <router-link to="/login" class="text-dark">
          <strong class="text-dark text-sm">
            Login into your account
          </strong>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
    passwordStrength
} from "check-password-strength";

import FormMessage from '@/components/FormMessage';

export default {
    name: "Register",
    components: {
        FormMessage,
    },
    data() {
        return {
            model: {
                email: "",
                phone: "",
                password: "",
                confirmPassword: "",
                role: "",
            },
            role: 'trainee',
            loading: false,
            successful: false,
            registerError: "",
            message: "",
            messageType: "",
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        passwordTest() {
            return passwordStrength(this.model.password);
        },
    },
    mounted() {
        if (this.loggedIn) {
            this.$router.push("/profile");
        }
        this.role = this.$route.params.role;
    },
    methods: {
        successFn(data) {
            if (data.accessToken != null) {
                window.localStorage.setItem("auth", JSON.stringify(data));
                this.$router.push("/profile");
            }
            this.loading = false;
        },
        failureFn(error) {
            this.loading = false;
            this.message = error.response != undefined ? error.response.data.message : "Something went wrong. <br> Please check your network and try again.";
            this.messageType = "danger";
        },
        handleRegister() {

            if (this.model.password != this.model.confirmPassword) {
                this.message = "Passwords do not match";
                this.messageType = "danger";
                return;
            }

            if (this.passwordTest.id < 1) {
                this.registerError = "Please use a Stronger Password";
                this.messageType = "danger";
                return;
            }

            this.loading = true;

            this.model.role = this.role;

            var obj = {
                model: this.model,
                success: this.successFn,
                failure: this.failureFn,
            };

            this.$store.dispatch("auth/register", obj);
        },
    },
};
</script>

<style></style>
