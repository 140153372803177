<template>
<div class="d-inline-flex">
  <label class="custom-toggle" :class="switchClass">
    <input type="checkbox" v-model="value" @change="triggerToggle()" />
    <span class="custom-toggle-slider rounded-circle">
    </span>
  </label>
  <!-- <h2>
      <strong class="my-auto ml-2">{{value? onText : offText}}</strong>
    </h2> -->
</div>
</template>

<script>
export default {
  name: "base-switch",
  props: {
    value: [Array, Boolean],
    type: String,
    onText: {
      type: String,
      default: "On",
    },
    offText: {
      type: String,
      default: "Off",
    },
  },
  emits: ["update:value"],
  computed: {
    switchClass() {
      let baseClass = "custom-toggle-";
      if (this.type) {
        return baseClass + this.type;
      }
      return "";
    },
  },
  methods: {
    triggerToggle() {
      this.$emit("update:value", this.value);
    },
  },
};
</script>

<style>
.custom-toggle input:checked+.custom-toggle-slider::after {
  content: attr(data-label-on);
  color: #0ba753;
  right: auto;
  left: 0;
}

.custom-toggle-slider::after {
  color: #ced4da;
  content: attr(data-label-off);
  display: inline-flex;
  font-family: inherit;
  font-weight: 600;
  font-size: .75rem;
  line-height: 24px;
  position: absolute;
  right: 0;
  margin: 0 .21667rem;
  top: 0;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
}

.custom-toggle {
  max-width: 48px !important;
  height: 1.7rem;
  min-width: 48px;
}

input:checked+.custom-toggle-slider::before {
  background: linear-gradient(87deg, #6574e0 0, #77232b 100%) !important;
  border: solid 0px #77132b26 !important;/*#11cdefbb*/
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}
</style>
