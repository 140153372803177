<template>
<div class="row justify-content-center">
  <div class="col-lg-5 col-md-7">
    <div class="card bg-secondary shadow border-0">
      <div class="card-body px-lg-5 py-lg-5">
        <div class="text-center text-muted mb-4">
          <h4>{{tokenIsValid ? 'Enter your new password' : loading ? '' : 'Try to reset your password again'}}</h4>
          <small v-if="tokenIsValid">You will receive a link to continue</small>
        </div>
        <form-message v-model:message="message" v-model:type="messageType"></form-message>
        <form role="form" v-if="tokenIsValid && !passwordChanged" @submit.prevent="resetPassword">

          <base-input :required="true" formClasses="input-group-alternative" placeholder="Password" type="password" addon-left-icon="ni ni-lock-circle-open" :disabled="loading" v-model:value="model.password">
          </base-input>

          <base-input :required="true" formClasses="input-group-alternative" placeholder="Confirm Password" type="password" addon-left-icon="ni ni-lock-circle-open" :disabled="loading" v-model:value="model.confirmPassword">
          </base-input>

          <div class="text-muted font-italic" v-if="model.password != ''">
            <small>Password Strength:
              <span class="font-weight-700" :class="passwordTest.id < 2
                                  ? 'text-danger'
                                  : passwordTest.id == 2
                                  ? 'text-info'
                                  : 'text-success'">
                {{ passwordTest.value }}
              </span></small>
          </div>

          <div class="text-muted font-italic" v-show="model.confirmPassword != '' && model.password != model.confirmPassword">
            <small>
              <span class="text-danger font-weight-700">
                Passwords do not match!
              </span></small>
          </div>
          <button type="submit" class="d-none" ref="formSubmitButton"></button>
          <div class="text-center">
            <base-button @click="$refs.formSubmitButton.click()" :loading="loading" type="primary" class="my-4"> Reset Password </base-button>
          </div>
        </form>
        <div class="d-block text-center" v-else-if="!passwordChanged">
          <base-button @click="$router.push({path: '/forgot-password'})" :loading="loading" type="white" class="my-5 bg-transparent">
            Reset Password
          </base-button>
        </div>
        <div class="d-block text-center" v-else>
          <base-button @click="$router.push({path: '/login'})" :loading="loading" type="white" class="my-5">
            Login With New Password
          </base-button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <!-- <router-link to="/register">
          <strong class="text-dark text-sm">
            Create new account
          </strong>
        </router-link> -->
      </div>
      <div class="col-6 text-right">
        <router-link to="/login" class="text-dark">
          <strong class="text-dark text-sm">
            Login into your account
          </strong>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  passwordStrength
} from "check-password-strength";

import AuthService from '@/api/services/auth.service';

import FormMessage from '@/components/FormMessage';

export default {
  name: "password-reset",
  components: {
    FormMessage,
  },
  data() {
    return {
      model: {
        password: "",
        confirmPassword: "",
      },
      loading: true,
      message: "",
      messageType: "",
      loading: false,
      tokenIsValid: false,
      passwordChanged: false,
    };
  },
  computed: {
    passwordTest() {
      return passwordStrength(this.model.password);
    },
  },
  methods: {
    resetPassword() {
      if (this.model.password != this.model.confirmPassword) {
        this.message = "Passwords do not match";
        this.messageType = "danger";
        return;
      }

      if (this.passwordTest.id < 1) {
        this.registerError = "Please use a Stronger Password";
        this.messageType = "danger";
        return;
      }

      AuthService.resetPassword({password: this.model.password, token: this.model.token}).then((response) => {
        
        this.message = response.data.message;
        this.messageType = "success";
        this.requestSent = true;
        this.passwordChanged = true;

      }).catch((error) => {

        this.message = error.response == undefined ?
          "Something went wrong. <br> Please check your network and try again." : error.response.data.message;
        this.messageType = "danger";
      });
    }
  },
  created() {
    if (!this.$route.params.token) {
      this.$router.push({
        path: '/login'
      })
    }

    this.model.token = this.$route.params.token;

    AuthService.verifyPasswordResetToken(this.$route.params.token).then((response) => {

      this.tokenIsValid = true;

    }).catch((error) => {

      this.message = error.response == undefined ?
        "Something went wrong. <br> Please check your network and try again." : error.response.data.message;
      this.messageType = "danger";

    }).finally(() => {
      this.loading = false;
    });
  }
};
</script>

<style></style>
