import axios from "axios";
import authHeader from "./auth-header";

import config from "../config";

var API_URL = config.API_URL;

class InternalVerifierService {

  getProfileForProgramme(programmeId) {
    return axios.get(API_URL + "internal-verifier/" + programmeId, { headers: authHeader() });
  }

  getInternalVerifier(userId) {
    return axios.get(API_URL + "internal-verifier/user/" + userId, { headers: authHeader() });
  }

  getInternalVerifiersByCentre(params) {
    return axios.get(API_URL + "internal-verifier/centre" + params, { headers: authHeader() });
  }

  getInternalVerifiersByCentreProgramme(id, params) {
    return axios.get(API_URL + `internal-verifier/centre/programme/${id}?${params}`, {
      headers: authHeader(),
    });
  }

  getVerificationsForLoggedInVerifier() {
    return axios.get(API_URL + "verification/internal-verifier", { headers: authHeader() });
  }

  getVerificationsByVerifierId(id) {
    return axios.get(API_URL + "verification/internal-verifier/" + id, { headers: authHeader() });
  }

  addInvites(emails, centreId) {
    return axios.post(API_URL + "internal-verifier/invite/" + centreId, emails, {
      headers: authHeader(),
    });
  }

  uploadInvites(formData, centreId) {
    return axios.post(API_URL + "internal-verifier/invite/xlsx/" + centreId, formData, {
      headers: authHeader(),
    });
  }

  getInvites(centreId) {
    return axios.get(API_URL + "internal-verifier/invite/" + centreId, {
      headers: authHeader(),
    });
  }

  acceptInvite(token) {
    return axios.put(API_URL + "internal-verifier/invite/" + token, {}, {
      headers: authHeader(),
    });
  }

  getRequests(params) {
    return axios.get(API_URL + "internal-verifier/request" + params, {
      headers: authHeader(),
    });
  }

  sendRequest(data) {
    return axios.post(API_URL + "internal-verifier/request", data, {
      headers: authHeader(),
    });
  }

  deleteRequest(id) {
    return axios.delete(API_URL + "internal-verifier/request/" + id, {
      headers: authHeader(),
    });
  }

}
export default new InternalVerifierService();
