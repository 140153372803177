<template>
<div class="content">
  <base-header class="header pb-8 pt-5 pt-md-6 d-flex align-items-center" style="
        background-image: url(/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
    <!-- Mask -->
    <span class="mask bg-gradient-default opacity-95" style="opacity: 9"></span>
    <!-- Header container -->
    <div class="container-fluid d-flex align-items-center mt-5">
      <div class="row">
        <div class="col-lg-7 col-md-10">
          <p class="text-white mt-0 mb-3">
            <!-- This is your profile page. You can see the progress you've made
              with your work and manage your projects or assigned tasks -->
          </p>
        </div>
      </div>
    </div>
  </base-header>
  <div class="container-fluid mt--7 pt-4 pb-5">

    <div class="mt-5">
      <card class="no-border-card" gradient="secondary" :shadow="true" body-classes="px-3 py-2" footer-classes="pb-2">
        <div class="row align-items-center">
          <div class="col-8 d-flex">
            <h3 class="mb-0">Verification Log</h3>
          </div>
          <div class="col-4 text-right">
            <base-button :loading="loading" @click="page = 1; loadVerifications" type="dark">
              <i class="fa fa-sync"> </i>
            </base-button>
          </div>
        </div>
      </card>
    </div>
    <div class="mt-5">
      <div class="timeline timeline-two-side" data-timeline-axis-style="dotted">
        <div  class="mb-3 timeline-block" v-for="(verification, index) in verifications">
          <span class="timeline-step">
            <i class="fa text-gradient" :class="`${(verification.valid)?'fa-check text-green':'fa-times text-primary'}`">
            </i>
          </span>
          <div class="timeline-content">

              <div class="row d-block badge badge-sm mx-1" :class="`bg-gradient-${(verification.valid && verification.sufficient)? 'green' : (verification.valid)? 'warning' : 'danger'}`" style="border-radius: 0.375rem 0.375rem 0 0;">
                <div class="col-12">
                  <span class="">Timestamp</span>
                  <span class=" float-right">{{verification.updatedAt}}</span>
                </div>
              </div>

              <card class="card-stats" :show-footer-line="true" gradient="secondary" :shadow="true" style="border-radius: 0 0 0.375rem 0.375rem;">
                <div class="row">
                  <div class="col-12">
                    <div name="icon" :class="`float-${(index%2)?'left':'right'}`">
                      <div class="icon icon-shape text-white rounded-circle shadow timeline-icon" :class="`bg-gradient-${(verification.valid && verification.sufficient)? 'green' : (verification.valid)? 'warning' : 'danger'}`" >
                        <i class="fa " :class="`${(verification.valid && verification.sufficient)? 'fa-check' : (verification.valid)? 'fa-question' : 'fa-times'}`"></i>
                      </div>
                    </div>
                    <div :class="`text-${(index%2)?'right':'left'}`">
                      <router-link :to="{path: `/assessor/portfolio/${verification.portfolioId}`}">
                        <h5 class="card-title text-titlecase text-muted mb-0">
                            {{verification.traineeName}}
                        </h5>
                        <span class="h2 font-weight-bold mb-0">
                          {{verification.activityTitle}}
                        </span>
                      </router-link>
                      <p class="">{{verification.remark ? verification.remark : 'No Remark' }}</p>
                    </div>
                  </div>
                </div>
                <footer>
                  <div class="row">
                    <div class="col-12">
                      <div class="mt-2" :class="`text-${(index%2)?'right':'left'}`">
                        <span class="badge badge-sm me-1 bg-gradient-primary mx-1">{{verification.courseTitle}}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mt-2" :class="`text-${(index%2)?'right':'left'}`">
                        <span class="badge badge-sm me-1 bg-purple mx-1">{{verification.centreName}}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mt-3" :class="`text-${(index%2)?'right':'left'}`">
                        <span class="badge badge-sm me-1 bg-gradient-gray-dark mx-1">{{verification.programmeTitle}}</span>
                        <a href="#" class="badge badge-sm me-1 mx-1" :class="`float-${(index%2)?'left':'right'} bg-gradient-${verification.valid ? verification.sufficient?'green':'warning':'red'}`">{{verification.valid ? verification.sufficient ? 'Sufficient' : 'Insufficient' : 'Invalid'}}</a>
                      </div>
                    </div>
                  </div>
                </footer>
              </card>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import InternalVerifierService from "@/api/services/internal-verifier.service";
import swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      verifications: [],
      loading: false,
      page: 0,
      last: false,

    };
  },
  computed: {
    user() {
      return JSON.parse(window.localStorage.getItem("auth")).user;
    },

  },
  methods: {
    loadVerifications() {
      this.loading = true;

      const verificationSwal = swal.mixin({
        customClass: {
          confirmButton: "btn btn-default btn-sm btn-fill",
        },
        buttonsStyling: false,
      });

      InternalVerifierService.getVerificationsForLoggedInVerifier(`?page=${this.page}`) .then((response) => {
        this.verifications = this.page == 0 ? response.data.result : {...this.verifications, ...response.data.result};
      }).catch((error) => {
        verificationSwal.fire({
          text: error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message,
          confirmButtonText: "Ok",
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  created() {
    this.loadVerifications();
;  },
  mounted() {},

};
</script>

<style scoped>
.timeline-icon {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08) !important;
  border: 3px solid #fff !important;
}
</style>
