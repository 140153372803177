<template>
<div class="content">
    <modal v-model:show="showAddModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
        <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
            <template v-slot:header>
                <div class="text-muted text-left mb--5">
                    <h3>Add Log </h3>
                </div>
                <!-- <base-button size="sm" type="danger" class="float-right mb--3" @click=" modals.showLogBookModal = false">
                                            Close
                                        </base-button> -->
            </template>
            <form role="form" @submit.prevent="addLogSheet(); showAddModal = false;">
            
                <div class="">
                    <div class="row">
                        <div class="col-sm-8">
                            <base-input :required="true" alternative="" label="What Activity Was Performed?" placeholder="Activity Title" input-classes="form-control-alternative" v-model:value="model.title" />
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="form-control-label">
                                    Portfolio Type <span class="text-danger">*</span>
                                </label>
                                <select class="form-control-alternative form-control" v-model="model.type" style="width: 100%">
                                    <option v-for="type in ['LOG', 'EVIDENCE']" :key="type" :value="type">
                                        {{ type }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <base-input :required="true" alternative=""  type="date" label="Log Date" placeholder="What Day are you adding this log for?" input-classes="form-control-alternative"  v-model:value="model.logDate"/>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="form-control-label">
                                    Time Span <span class="text-danger">*</span>
                                </label>
                                <select class="form-control-alternative form-control" v-model="model.timeSpan" style="width: 100%">
                                    <option v-for="type in ['DAILY', 'WEEKLY']" :key="type" :value="type">
                                        {{ type }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="form-control-label">
                                    Evidence Type <span class="text-danger">*</span>
                                </label>
                                <select class="form-control-alternative form-control" v-model="model.timeSpan" style="width: 100%">
                                    <option v-for="type in ['DAILY', 'WEEKLY']" :key="type" :value="type">
                                        {{ type }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3" v-if="model.dataType == 'MEDIA'">
                        <div class="col-sm-12">
                            <input required type="file" ref="selectMediaInput" class="d-none" />
                            <base-button type="dark" @click="$refs.selectMediaInput.click();" class=" btn-block">
                                Select Media <small>(Required)</small>
                            </base-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <base-input :required="true" alternative="" :label="model.dataType != 'MEDIA'? 'How was the activity carried out?' : 'Describe the uploaded content'">
                                <textarea :rows="model.dataType != 'MEDIA'? 6 : 2" v-model="model.description" class="form-control form-control-alternative" :placeholder="model.dataType != 'MEDIA'? 'How was the the activity carried out?' : 'Brief description of the uploaded content'"></textarea>
                            </base-input>
                        </div>
                    </div>

                <button class="d-none" ref="addLogSheetFormButton"  type="submit"></button>
                </div>
            </form>
            <template v-slot:footer>
                <div class="">
                    <base-button type="warning" @click="showAddModal = false" class="my-1">
                        Close
                    </base-button>
                    <base-button type="info" @click="$refs.addLogSheetFormButton.click();" class="my-1 float-right">
                        Save
                    </base-button>
                </div>
            </template>
        </card>
    </modal>
    <modal v-model:show="showRemarkModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
        <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
            <template v-slot:header>
                <div class="text-muted text-left mb--5">
                    <h3>View Log Remark</h3>
                </div>
            </template>
            <div class="row p-3 mb-3 bg-white" style="border-radius: 10px;" v-for="r in model.remarks" :key="r.id">
                <div class="col-sm-12">
                    <base-input readonly alternative="" label="Name" input-classes="form-control-alternative" :value="r.name" />
                </div>
                <div class="col-sm-6">
                    <base-input readonly alternative="" label="Date" input-classes="form-control-alternative" :value="r.role" />
                </div>
                <div class="col-sm-6">
                    <base-input readonly alternative="" label="Date" input-classes="form-control-alternative" :value="r.createdAt" />
                </div>
                <div class="col-sm-12">
                    <base-input :required="true" alternative="" label="Remark">
                        <textarea readonly :rows="4" v-model="r.text" class="form-control bg-white form-control-alternative"></textarea>
                    </base-input>
               </div>

            </div>
            <form class="mt-5" role="form" @submit.prevent="addRemark(); showRemarkModal = false;">

                <div class="">
                    <div class="row">
                        <div class="col-sm-12">
                            <base-input readonly alternative="" label="Name" input-classes="form-control-alternative" v-model:value="user.name" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <base-input :required="true" alternative="" label="Remark">
                                <textarea :rows="4" v-model="remark.text" class="form-control form-control-alternative" placeholder="Please input your comments and remarks"></textarea>
                            </base-input>
                        </div>
                    </div>

                <button class="d-none" ref="addRemarkFormButton"  type="submit"></button>
                </div>
            </form>
            <template v-slot:footer>
                <div class="">
                    <base-button type="warning" @click="showRemarkModal = false" class="my-1">
                        Close
                    </base-button>
                    <base-button type="info" @click="$refs.addRemarkFormButton.click();" class="my-1 float-right">
                        Save
                    </base-button>
                </div>
            </template>
        </card>
    </modal>
    <modal v-model:show="showViewModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
            <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
                <template v-slot:header>
                    <div class="text-muted text-left mb--5">
                        <h3>View Log </h3>
                    </div>
                </template>
                <form role="form">

                    <div class="">
                        <div class="row">
                            <div class="col-sm-12">
                                <base-input :readonly="true" alternative="" label="Activity Title" input-classes="form-control-alternative" v-model:value="model.title" />
                            </div>
                            <div class="col-sm-4">
                                <base-input :readonly="true" alternative="" label="Sheet Type" input-classes="form-control-alternative" v-model:value="model.timeSpan" />
                            </div>
                            <div class="col-sm-4">
                                <base-input :readonly="true" alternative="" label="Log Type" input-classes="form-control-alternative" v-model:value="model.dataType" />
                            </div>
                            <div class="col-sm-4">
                                <base-input :readonly="true" alternative="" label="Log Date" input-classes="form-control-alternative"  v-model:value="model.logDate"/>
                            </div>
                        </div>
                        <div class="row mb-3" v-if="model.dataType == 'MEDIA'">
                            <div class="col-sm-12">
                                <iframe :src="model.mediaUrl" style="border: 2px solid #77132b33; border-radius: 10px; width: 100%" :title="model.title"></iframe>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <base-input :required="true" alternative="" :label="model.dataType != 'MEDIA'? 'How was the activity carried out?' : 'Describe the uploaded content'">
                                    <textarea readonly :rows="model.dataType != 'MEDIA'? 6 : 2" v-model="model.description" class="form-control form-control-alternative bg-white" :placeholder="model.dataType != 'MEDIA'? 'How was the the activity carried out?' : 'Brief description of the uploaded content'"></textarea>
                                </base-input>
                            </div>
                        </div>
                    </div>
                </form>
                <template v-slot:footer>
                    <div class="">
                        <base-button type="warning" @click="showViewModal = false" class="my-1 float-right">
                            Close
                        </base-button>
                    </div>
                </template>
            </card>
        </modal>
        <modal v-if="model.evidences!=undefined" v-model:show="showEvidenceModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
            <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-sm-8 text-muted text-left mb--5">
                            <h3>View Log Evidences</h3>
                        </div>
                        <div class="col-sm-4 text-muted text-right mb--5">
                            <input class="d-none" accept="image/*" type="file" ref="addEvidenceFileInput" @change="uploadEvidence();">
                            (Max Size: 4MB)
                            <base-button :loading="uploadingEvidence" type="warning" size="sm" class="float-right" @click="$refs.addEvidenceFileInput.click()">
                                <i class="fa fa-plus"></i> Photo Evidence
                            </base-button>
                        </div>
                    </div>
                </template>
                <div class="row">
                    <div v-for="(evidence, index) in model.evidences" :key="index" class="col-sm-6 ">
                        <div class="row p-2" v-if="evidence.evidenceType=='PHOTO'" >
                            <img :src="evidence.url" class="col-9" style="border: 2px solid #77132b33; border-radius: 10px; height: 150px">
                            <base-button class="col-2 m-2" type="danger" size="sm" @click="deleteEvidenceUrl(index)" icon>
                                <i class="fa fa-times"></i>
                            </base-button>
                        </div>
                    </div>
                </div>
                <template v-slot:footer>
                    <div class="">
                        <base-button type="warning" @click="showEvidenceModal = false" class="my-1 float-right">
                            Close
                        </base-button>
                    </div>
                </template>
            </card>
        </modal>
        <modal v-if="model.evidences!=undefined" v-model:show="showVideoEvidenceModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
            <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-sm-8 text-muted text-left mb--5">
                            <h3>View Log Video Evidences</h3>
                        </div>
                        <div class="col-sm-4 text-muted text-right mb--5">
                            <input class="d-none" accept="video/*" capture="camcorder" type="file" ref="addVideoEvidenceFileInput" @change="uploadVideoEvidence();">
                            (Max Size: 32MB)
                            <base-button :loading="uploadingVideoEvidence" type="warning" size="sm" class="float-right" @click="$refs.addVideoEvidenceFileInput.click()">
                                <i class="fa fa-plus"></i> Video Evidence
                            </base-button>
                        </div>
                    </div>
                </template>
                <div class="row">
                    <div v-for="(evidence, index) in model.evidences" :key="index" class="col-sm-6 ">
                        <div class="row p-2" v-if="evidence.evidenceType=='VIDEO'">
                            <iframe :src="evidence.url"  class="col-9" style="border: 2px solid #77132b33; border-radius: 10px; height: 150px">
                            </iframe>
                            <base-button class="col-2 m-2" type="danger" size="sm" @click="deleteVideoEvidenceUrl(index)" icon>
                                <i class="fa fa-times"></i>
                            </base-button>
                        </div>
                    </div>
                </div>
                <template v-slot:footer>
                    <div class="">
                        <base-button type="warning" @click="showVideoEvidenceModal = false" class="my-1 float-right">
                            Close
                        </base-button>
                    </div>
                </template>
            </card>
        </modal>
    <base-header class="header pb-8 pt-5 pt-md-6 d-flex align-items-center" style="
        background-image: url(/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
        <!-- Mask -->
        <span class="mask bg-gradient-default opacity-95"></span>
        <!-- Header container -->
        <div class="container-fluid d-flex align-items-center mt-5">
            <div class="row">
                <div class="col-lg-7 col-md-10">
                    <p class="text-white mt-0 mb-3">
                        <!-- This is your profile page. You can see the progress you've made
              with your work and manage your projects or assigned tasks -->
                    </p>
                </div>
            </div>
        </div>
    </base-header>
    <div class="container-fluid mt--7">

        <div>
            <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
                <template v-slot:header>
                    <div class="row align-items-center">
                        <div class="col-sm-4 d-flex">
                            <h3 class="mb-0">Portfolio </h3>
                           <h4 class="ml-2"><small>(logs & evidences)</small></h4>
                        </div>
                        <div class="col-sm-8 text-right">
                            <a class="btn-sm mr-5" target="_blank" download href="/logsheet.pdf">
                                Download Log Sheet Template
                            </a>
                            <!--
                            <base-button :loading="showAddModal || addingLogSheet" @click="showUploadModal = true" class="btn-sm mr-5">
                                Upload Item
                            </base-button>
                            -->
                            <base-button :loading="showAddModal || addingLogSheet" @click="model = {}; showAddModal = true" class="btn-sm mr-5">
                                <i class="fa fa-plus"></i>
                                Portfolio Item
                            </base-button>
                            <base-button @click="loadTable" type="dark">
                                <i class="fa fa-sync"> </i>
                            </base-button>
                        </div>
                    </div>
                </template>
                <div class="px-3">
                    <form-message :type="upload.messageType" v-model:message="upload.message"></form-message>
                </div>
                <div class="px-3">
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <el-select @change="loadTable" class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Per page">
                            <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>

                        <div class="mb-3 col-sm-5 col-lg-4">
                            <div class="row">
                                <el-input type="search" class="col-10" clearable placeholder="Filter Records" v-model="searchQuery" aria-controls="datatables">
                                </el-input>
                                <base-button type="default" @click="loadTable">
                                    <i class="fa fa-search"></i>
                                </base-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="loading" class="text-center">
                        <div class="lds-grid">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <el-table v-show="!loading" :data="tableData" row-key="id" class="px-3" header-row-class-name="thead-light">
                        <!-- <el-table-column label="Select">
                            <template v-slot:default="props">
                                <input class="ml-3" type="checkbox" v-model="selection" :value="props.row.id">
                            </template>
                        </el-table-column> -->
                        <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column" sortable>
                        </el-table-column>
                        <el-table-column min-width="40px" align="left" label="View">
                            <template v-slot:default="props">
                                <div class="d-flex">
                                    <base-button :loading="props.row.loading" @click="handleView(props.row)" class="like btn-link" type="default" size="sm" icon>
                                        <i class="text-white fa fa-eye"></i>
                                    </base-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="60px" align="left" label="Evidence">
                            <template v-slot:default="props">
                                <div class="d-flex">
                                    <base-button :loading="props.row.loading" @click="model = props.row; showEvidenceModal = true" type="dark" size="sm" icon>
                                        <i class="text-white fa fa-image"></i>
                                    </base-button>
                                    <base-button :loading="props.row.loading" @click="model = props.row; showVideoEvidenceModal = true" type="dark" size="sm" icon>
                                        <i class="text-white fa fa-play"></i>
                                    </base-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="40px" align="left" label="Remarks">
                            <template v-slot:default="props">
                                <div class="d-flex">
                                    <base-button :loading="props.row.loading" @click="model = props.row; showRemarkModal = true" class="like btn-link" type="dark" size="sm" icon>
                                        <i class="text-white fa fa-edit"></i>
                                    </base-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="50px" align="left" label="Delete">
                            <template v-slot:default="props">
                                <div class="d-flex" v-if="props.row.supervised == false || props.row.supervised == null">
                                    <base-button :loading="props.row.loading" @click="handleDelete(props.$index, props.row)" class="like btn-link" type="danger" size="sm" icon>
                                        <i class="text-white fa fa-times"></i>
                                    </base-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <template v-slot:footer>
                    <div v-if="!loading" class="col-12  d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <!-- <div class="col-sm-6">
                            <base-button @click="handleDelete(props.$index, props.row)" class="like btn-link" type="danger" size="sm" icon>
                                        Delete 
                                    </base-button>
                        </div> -->
                        <div class="">
                            <p class="card-category">
                                Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} log sheets
                            </p>
                        </div>
                        <base-pagination class="pagination-no-border" v-model:value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total" @update:value="loadTable">
                        </base-pagination>
                    </div>
                </template>
            </card>
        </div>
    </div>

</div>
</template>

<script>
import {
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import LogSheetService from "@/api/services/portfolio-item.service";
import BaseInput from '@/components/BaseInput.vue';
import FormMessage from "../../components/FormMessage.vue";
import BaseCheckbox from "../../components/BaseCheckbox.vue";

export default {
    components: {
        BasePagination,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElTable.name]: ElTable,
        [ElInput.name]: ElInput,
        [ElTableColumn.name]: ElTableColumn,
        BaseInput,
        FormMessage,
        BaseCheckbox
    },
    data() {
        return {
            upload: {
                loading: false,
                message: "",
                messageType: ""
            },
            id: "",
            showAddModal: false,
            showViewModal: false,
            showEvidenceModal: false,
            showVideoEvidenceModal: false,
            showRemarkModal: false,
            addingLogSheet: false,
            uploadingEvidence: false,
            uploadingVideoEvidence: false,
            model: {
                type: '',
                dataType: '',
                timeSpan: '',
            },
            selection: [],
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [1, 2, 3, 10, 25, 50, 100, 250, 500],
                total: 0,
            },
            loaded: false,
            loading: true,
            query: "",
            searchQuery: "",
            propsToSearch: ["title", "dataType", "timeSpan"],
            tableColumns: [{
                    prop: "title",
                    label: "Activity Title",
                    minWidth: 100,
                },
                {
                    prop: "dataType",
                    label: "Log Type",
                    minWidth: 60,
                },
                {
                    prop: "timeSpan",
                    label: "Time Span",
                    minWidth: 60,
                },
                {
                    prop: "logDate",
                    label: "Date",
                    minWidth: 70,
                },
            ],
            tableData: [],
            fuseSearch: null,
            searchedData: [],
            logBook: {},
            remark: {},

        };
    },
    computed: {
        pagedData() {
            return this.tableData;
        },
        /***
         * Searches through table data and returns a paginated array.
         * Note that this should not be used for table with a lot of data as it might be slow!
         * Do the search and the pagination on the server and display the data retrieved from server instead.
         * @returns {computed.pagedData}
         */

        queriedData() {
            if (!this.searchQuery) {
                return this.pagedData;
            }
            let result = this.tableData.filter((row) => {
                let isIncluded = false;
                for (let key of this.propsToSearch) {
                    let rowValue = (row[key] + "").toLowerCase().toString();
                    if (
                        rowValue.includes &&
                        rowValue.includes(this.searchQuery.toLowerCase())
                    ) {
                        isIncluded = true;
                    }
                }
                return isIncluded;
            });

            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.pagination.total < highBound) {
                highBound = this.pagination.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        downloadTemplateLink() {
            return TraineeService.getDownloadTemplateLink();
        },
        user() {
            return JSON.parse(window.localStorage.getItem("auth")).user;
        },

    },
    methods: {
        async loadTable() {

            this.loading = true

            if (this.from > this.pagination.total) {
                this.pagination.currentPage = 1;
            }

            var params = this.id + "?size=" + this.pagination.perPage + "&page=" + (this.pagination.currentPage - 1);

            if (this.searchQuery.length > 0) {
                params = params + "&s=" + this.searchQuery;
            }

            var response = await LogSheetService.getLogSheets(params);

            this.loading = false;
            if (response.data.result != (null || undefined)) {
                this.tableData = response.data.result;
                this.pagination.total = response.data.totalElements
                this.pagination.perPage = response.data.size
                this.pagination.currentPage = response.data.page + 1
            }

            return this.tableData;
        },
        addLogSheet() {

            this.addingLogSheet = true;

            const addSwal = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-default btn-sm btn-fill",
                },
                buttonsStyling: false,
            });

            var formData = new FormData();

            formData.append('logSheet', JSON.stringify(this.model));

            if(this.model.dataType == "MEDIA"){

                formData.append('media', this.$refs.selectMediaInput.files[0], this.$refs.selectMediaInput.files[0].name);
            }

            LogSheetService.addLogSheet(this.id, formData)
                .then((response) => {
                    addSwal.fire({
                        text: response.data.message,
                        confirmButtonText: "Ok",
                    });
                    this.tableData.push(response.data.result);
                    this.pagination.total = response.data.result.length
                }).catch((error) => {
                    addSwal.fire({
                        text: error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message,
                        confirmButtonText: "Ok",
                    });
                }).finally(() => {
                    this.addingLogSheet = false;
                })
        },
        handleView(row) {
            this.model = row;
            this.showViewModal = true;
        },
        uploadEvidence() {

            this.uploadingEvidence = true;

            var formData = new FormData();

            formData.append("evidence", this.$refs.addEvidenceFileInput.files[0], this.$refs.addEvidenceFileInput.files[0].name);

            const uploadSwal = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-default btn-sm btn-fill",
                },
                buttonsStyling: false,
            });
            if(4197152 < this.$refs.addEvidenceFileInput.files[0].size){
                uploadSwal.fire({
                    text: "File selected is too large",
                    confirmButtonText: "Ok"
                });

                this.uploadingEvidence = false;
                return;
            }
            LogSheetService.uploadLogSheetEvidence(this.model.id, formData).then((response) => {
                uploadSwal.fire({
                    text: response.data.message,
                    confirmButtonText: "Ok"
                });
                this.model = response.data.result;
                this.showEvidenceModal = true;
            }).catch((error) => {
                    uploadSwal.fire({
                        text: error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message,
                        confirmButtonText: "Ok",
                    });
                    this.showEvidenceModal = false;
            }).finally(() => {
                this.uploadingEvidence = false;
            });
        },
        deleteEvidenceUrl(index){
            const deleteEvidenceSwal = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-default btn-sm btn-fill",
                },
                buttonsStyling: false,
            });
            LogSheetService.deleteEvidenceUrl(this.model.id, index).then((response) => {
                deleteEvidenceSwal.fire({
                    text: response.data.message,
                    confirmButtonText: "Ok"
                });
                this.model.evidenceUrls.splice(index, 1);
                this.showEvidenceModal = true;
            }).catch((error) => {
            }).finally(() => {
            });
        },
        uploadVideoEvidence() {

            this.uploadingVideoEvidence = true;

            var formData = new FormData();

            formData.append("evidence", this.$refs.addVideoEvidenceFileInput.files[0], this.$refs.addVideoEvidenceFileInput.files[0].name);

            const uploadSwal = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-default btn-sm btn-fill",
                },
                buttonsStyling: false,
            });
            if(34971520 < this.$refs.addVideoEvidenceFileInput.files[0].size){
                uploadSwal.fire({
                    text: "File selected is too large",
                    confirmButtonText: "Ok"
                });

                this.uploadingVideoEvidence = false;
                return;
            }
            LogSheetService.uploadLogSheetVideoEvidence(this.model.id, formData).then((response) => {
                uploadSwal.fire({
                    text: response.data.message,
                    confirmButtonText: "Ok"
                });
                this.model = response.data.result;
                this.showVideoEvidenceModal = true;
            }).catch((error) => {
                    uploadSwal.fire({
                        text: error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message,
                        confirmButtonText: "Ok",
                    });
                    this.showVideoEvidenceModal = false;
            }).finally(() => {
                this.uploadingVideoEvidence = false;
            });
        },
        deleteVideoEvidenceUrl(index){
            const deleteEvidenceSwal = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-default btn-sm btn-fill",
                },
                buttonsStyling: false,
            });
            LogSheetService.deleteVideoEvidenceUrl(this.model.id, index).then((response) => {
                deleteEvidenceSwal.fire({
                    text: response.data.message,
                    confirmButtonText: "Ok"
                });
                this.model.videoEvidenceUrls.splice(index, 1);
                this.showVideoEvidenceModal = true;
            }).catch((error) => {
            }).finally(() => {
                this.uploadingVideoEvidenvce = false;
            });
        },
        addRemark() {
            const remarkSwal = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-default btn-sm btn-fill",
                },
                buttonsStyling: false,
            });

            LogSheetService.addRemark(this.model.id, this.remark).then((response) => {
                remarkSwal.fire({
                    text: response.data.message,
                    confirmButtonText: "Ok"
                });
                window.location.reload();
            }).catch((error) => {
            }).finally(() => {
                this.addingRemark = false;
            });
        },
        handleDelete(index, row) {
            const swalWithBootstrapButtons3 = swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons3
                .fire({
                    title: "Are you sure?",
                    text: `Deleting ${row.title}`,
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                })
                .then((result) => {
                    if (result.value) {
                        this.deleteRow(row);
                        swalWithBootstrapButtons3.fire({
                            title: "Deleted!",
                            text: `You deleted ${row.title}`,
                        });
                    }
                });
        },

        uploadFile() {
            var formData = new FormData();
            formData.append("file", this.$refs.uploadFileInput.files[0], "centres");

            this.upload.loading = true;
            TraineeService.uploadLogBooks(formData).then((response) => {
                this.loadTable();
                this.upload.message = response.data.message;
                this.upload.messageType = "success";
            }).catch((error) => {
                this.upload.message = error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message
                this.upload.messageType = "danger";
            }).finally(() => {
                this.upload.loading = false;
            });
        },
        deleteRow(row) {

            row.loading = true;
            LogSheetService.deleteLogSheet(row.id).then(() => {

                if (indexToDelete >= 0) {
                    this.tableData.splice(indexToDelete, 1);
                }
            }).catch(() => {

                row.loading = false;

            }).finally(() => {

            });
        },
    },
    created(){
        
    },
    mounted() {
    },

};
</script>

<style scoped>
.no-border-card .card-footer {
    border-top: 0;
    border-top: 0;
}

.modal-centre-img {
    border-radius: 100%;
    border: 4px solid rgb(187, 100, 121);
    width: 180px;
    height: 180px;
    object-fit: cover;
    box-shadow: 0px 3px 10px grey !important;
}

.lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #77132b;
    animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}

@keyframes lds-grid {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}
</style>
