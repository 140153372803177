<template>
<div class="content">
  <modal v-model:show="showAssessmentModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
      <template v-slot:header>
        <div class="text-muted text-left mb--5">
          <h3>Assess Portfolio Item</h3>
        </div>
      </template>
      <form v-if="model.assessment" class="mt-5" role="form" @submit.prevent="assessItem(); showAssessmentModal = false;">

        <div class="">
          <div class="row">
            <div class="col-sm-12">
              <base-input readonly alternative="" label="Name" input-classes="form-control-alternative" v-model:value="user.name" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">

            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <base-checkbox v-model:checked="model.assessment.valid" class="custom-control-alternative">
                <strong class="text-bold">{{model.assessment.valid ? 'Valid' : 'Invalid'}}</strong>
              </base-checkbox>
            </div>
            <div class="col-6">
              <base-checkbox v-model:checked="model.assessment.sufficient" class="custom-control-alternative">
                <strong class="text-bold">{{model.assessment.sufficient? 'Sufficient' : 'Insufficient'}}</strong>
              </base-checkbox>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-sm-12">
              <base-input :required="true" alternative="" label="Remark">
                <textarea :rows="4" v-model="model.assessment.remark" class="form-control form-control-alternative" placeholder="Please input your comments and assessments"></textarea>
              </base-input>
            </div>
          </div>

          <button class="d-none" ref="assessItemFormButton" type="submit"></button>
        </div>
      </form>
      <template v-slot:footer>
        <div class="">
          <base-button type="warning" @click="showAssessmentModal = false" class="my-1">
            Close
          </base-button>
          <base-button type="info" @click="$refs.assessItemFormButton.click();" class="my-1 float-right">
            Save
          </base-button>
        </div>
      </template>
    </card>
  </modal>
  <modal v-model:show="showViewModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
      <template v-slot:header>
        <div class="text-muted text-left mb--5">
          <h3>View {{model.type == 'LOG' ? 'Log' : 'Evidence'}} </h3>
        </div>
      </template>
      <form role="form">

        <div class="">
          <div class="row">
            <div class="col-sm-12">
              <base-input :readonly="true" alternative="" label="Activity Title" input-classes="form-control-alternative" v-model:value="model.title" />
            </div>
            <div class="col-sm-4">
              <base-input :readonly="true" alternative="" label="Type" input-classes="form-control-alternative" v-model:value="model.type" />
            </div>
            <div class="col-sm-4" v-if="model.type === 'EVIDENCE'">
              <base-input :readonly="true" alternative="" label="Evidence Type" input-classes="form-control-alternative" v-model:value="model.evidenceType" />
            </div>
            <div class="col-sm-4">
              <base-input :readonly="true" alternative="" label="Date" input-classes="form-control-alternative" v-model:value="model.logDate" />
            </div>
          </div>
          <div class="row mb-3" v-if="model.dataType == 'MEDIA'">
            <div class="col-sm-12">
              <iframe :src="model.mediaUrl" style="border: 2px solid #77132b33; border-radius: 10px; width: 100%" :title="model.title"></iframe>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <base-input alternative="" :label="model.dataType != 'MEDIA'? 'How the activity was carried out' : 'Describe the uploaded content'">
                <textarea readonly :rows="model.dataType != 'MEDIA'? 6 : 2" v-model="model.description" class="form-control form-control-alternative bg-white" :placeholder="model.dataType != 'MEDIA'? 'How the activity was carried out' : 'Brief description of the uploaded content'"></textarea>
              </base-input>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <div class="">
          <base-button type="warning" @click="showViewModal = false" class="my-1 float-right">
            Close
          </base-button>
        </div>
      </template>
    </card>
  </modal>
  <modal v-model:show="showTrackingModal" body-classes="p-0" modal-classes="modal-dialog-centered ">
    <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
      <template v-slot:header>
        <div class="row">
          <div class="col-sm-6 text-muted text-left mb--5">
            <h3>NSQ Tracking</h3>
          </div>
          <div class="col-sm-6 text-muted text-right mb--5" v-if="portfolio.course">
            <a :href="`/${String(portfolio.course.title).toLowerCase().replace(' ', '-')}.pdf`" target="_blank" download class="float-right btn btn-warning btn-sm" title="National Occupation Standards">
              <i class="fa fa-download"></i> Download NOS
            </a>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-12" v-if="model.tracks">
          <form role="form" @submit.prevent="saveTracking();">
            <div class="">
              <b class="text-muted text-small">NSQ Tracking Codes</b> <small class="text-muted"> [ L / U / Lo / Pc ]</small>
              <br>
              <div class="row my-3"></div>
              <div class="row" v-for="(track, index) in model.tracks" :key="index">
                <div class="col-sm-10">
                  <base-input :required="true" alternative="" placeholder="L / U / Lo / Pc" input-classes="form-control-alternative" v-model:value="model.tracks[index]" />
                </div>
                <div class="col-sm-2 float-right text-right">
                  <base-button type="info" v-if="index == 0" @click="model.tracks.push('')" icon>
                    <i class="fa fa-plus"></i>
                  </base-button>
                  <base-button type="warning" v-else @click="model.tracks.splice(index, 1)" icon>
                    <i class="fa fa-minus"></i>
                  </base-button>
                </div>
              </div>
              <button class="d-none" ref="saveTracksSubmitButton" type="submit"></button>
            </div>
            <!-- {{add.emails}} -->
          </form>
        </div>
      </div>
      <template v-slot:footer>
        <div class="">
          <base-button type="warning" @click="showTrackingModal = false; model = {}" class="my-1">
            Close
          </base-button>
          <base-button type="info" @click="$refs.saveTracksSubmitButton.click();" class="my-1 float-right">
            Save
          </base-button>
        </div>
      </template>
    </card>
  </modal>
  <modal v-if="model.evidences!=undefined" v-model:show="showEvidenceModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
      <template v-slot:header>
        <div class="row">
          <div class="col-sm-8 text-muted text-left mb--5">
            <h3>View Further Picture Evidences</h3>
          </div>
        </div>
      </template>
      <div class="row">
        <div v-for="(evidence, index) in model.evidences" :key="index" class="col-sm-6 ">
          <div class="row p-2">
            <img v-if="evidence.evidenceType=='PHOTO'" :src="evidence.url" class="col-12" style="border: 2px solid #77132b33; border-radius: 8px; height: 150px">
            <iframe v-if="evidence.evidenceType=='VIDEO'" :src="evidence.url" class="col-12" style="border: 2px solid #77132b33; border-radius: 8px; height: 150px">
            </iframe>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="">
          <base-button type="warning" @click="showEvidenceModal = false" class="my-1 float-right">
            Close
          </base-button>
        </div>
      </template>
    </card>
  </modal>
  <modal v-if="model.evidences!=undefined" v-model:show="showVideoEvidenceModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
      <template v-slot:header>
        <div class="row p-2">
          <div class="col-sm-8 text-muted text-left mb--5">
            <h3>View Further Video Evidences</h3>
          </div>
        </div>
      </template>
      <div class="row">
        <div v-for="(evidence, index) in model.evidences" :key="index" class="col-sm-6 ">
          <div class="row" v-if="evidence.evidenceType=='VIDEO'">
            <iframe :src="evidence.url" class="col-12" style="border: 2px solid #77132b33; border-radius: 8px; height: 150px">
            </iframe>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="">
          <base-button type="warning" @click="showVideoEvidenceModal = false" class="my-1 float-right">
            Close
          </base-button>
        </div>
      </template>
    </card>
  </modal>
  <modal v-model:show="showPhotoModal" body-classes="p-0" modal-classes="modal-dialog-centered">
    <card type="orange" shadow header-classes="pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
      <template v-slot:header>
        <div class="text-white text-left mb--5">
          <h3>{{portfolio.name}}'s Profile Picture</h3>
        </div>
      </template>
      <form role="form" class="text-center">
        <label class="img-overlay-wrapper" style="border-radius:100%;">
          <img :src="portfolio.photoUrl == null ? '/img/theme/user.jpg' : portfolio.photoUrl" class=" rounded-circle portfolio-modal-img" />
        </label>
      </form>
      <template v-slot:footer>
        <div class="">
          <base-button type="danger" @click="showPhotoModal = false;" class="my-1 float-right">
            Close
          </base-button>
        </div>
      </template>
    </card>
  </modal>
  <base-header class="header pb-8 pt-5 pt-md-6 d-flex align-items-center" style="
        background-image: url(/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
    <!-- Mask -->
    <span class="mask bg-gradient-default opacity-95"></span>
    <!-- Header container -->
    <div class="container-fluid d-flex align-items-center mt-5">
      <div class="row">
        <div class="col-lg-7 col-md-10">
          <p class="text-white mt-0 mb-3">
            <!-- This is your profile page. You can see the progress you've made
              with your work and manage your projects or assigned tasks -->
          </p>
        </div>
      </div>
    </div>
    <div class="container-fluid my-5">
      <div class="row">
        <div class="col-xl-5 mt-5 mb-xl-0 ml-lg-4">
          <div class="card card-profile shadow">
            <div class="row justify-content-left">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image bg-gradient-white">
                  <a href="#" class="image-overlay-wrapper">
                    <img @click="showPhotoModal = true" :src="portfolio.photoUrl == null ? '/img/theme/user.jpg' : portfolio.photoUrl" class=" rounded-circle portfolio-img">
                  </a>
                </div>
              </div>
            </div>
            <div class="card-header text-center bg-transparent">
              <h3 class="mb-0 portfolio-name">{{portfolio.name}} </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 mt-2 mx-1 mb-xl-0 ml-lg-4">
          <div class="card card-profile shadow">
            <div class="card-header p-1 px-2 bg-transparent">
              <div class="row text-gray-dark">
                <span class="col-4 ">
                  <strong class="mb-0">Course</strong>
                </span>
                <span class="col-8">
                  <strong class="mb-0 float-right">{{portfolio.course ? portfolio.course.title : ''}} </strong>
                </span>
              </div>
            </div>
          </div>
          <div class="card card-profile mt-2 mx-2" style="border-radius: 0.375rem 0.375rem 20px 20px;">
            <div class="card-header p-1 px-2 bg-transparent">
              <div class="row text-gray-dark">
                <span class="col-4 ">
                  <strong class="mb-0">Phone</strong>
                </span>
                <span class="col-8">
                  <strong class="mb-0 float-right">{{portfolio.phone}} </strong>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </base-header>
  <div class="container-fluid mt--5">

    <div>
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <template v-slot:header>
          <div class="row align-items-center">
            <div class="col-sm-4 d-flex">
              <h3 class="mb-0">Portolfio Items </h3>
            </div>
            <div class="col-sm-8 text-right">
              <base-button :loading="loading" @click="loadTable" type="dark">
                <i class="fa fa-sync"> </i>
              </base-button>
            </div>
          </div>
        </template>
        <div class="px-3">
          <form-message :type="upload.messageType" v-model:message="upload.message"></form-message>
        </div>
        <div class="px-3">
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

            <div class="row col-sm-6">
              <div class="form-group col-sm-8 col-md-8 col-lg-5">
                <label class="form-control-label">Items Per Page</label>
                <select @change="loadTable" class="form-control form-control-alternative" v-model="pagination.perPage" placeholder="Per Page">
                  <option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                  </option>
                </select>
              </div>
            </div>

            <div class="my-3 mr-4 col-sm-6 col-lg-5">
              <div class="row">
                <base-input type="search" class="col-10 " clearable placeholder="Search Trainees" v-model:value="searchQuery" input-classes="form-control form-control-alternative">
                </base-input>
                <div class="col-2">
                  <base-button type="default" @click="loadTable">
                    <i class="fa fa-search"></i>
                  </base-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loading" class="text-center">
            <div class="lds-grid">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <el-table :highlight-current-row="true" :expand="true" v-show="!loading" :data="tableData" row-key="id" class="px-3" header-row-class-name="thead-light">
            <!-- <el-table-column label="Select">
                            <template v-slot:default="props">
                                <input class="ml-3" type="checkbox" v-model="selection" :value="props.row.id">
                            </template>
                        </el-table-column> -->
            <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column" sortable>
            </el-table-column>
            <el-table-column align="center" label="Type">
              <template v-slot:default="props">
                <div class="d-block text-center">
                  <base-button :loading="props.row.loading" class="like btn-link" text-color="white" :type="props.row.type === 'LOG'?'info':'dark'" size="sm" icon>
                    {{props.row.type === 'LOG' ? 'Log': 'Evidence'}}
                  </base-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Evidences">
              <template v-slot:default="props">
                <div class="d-block text-center">
                  <base-button :loading="props.row.loading" @click="model = props.row; showEvidenceModal = true" type="default" size="sm" icon>
                    <i class="text-white fa fa-image"></i>
                  </base-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Tracking">
              <template v-slot:default="props">
                <div class="d-block text-center">
                  <base-button :loading="props.row.loading" @click="handleTrackingView(props.row)" class="like btn-link bg-purple" type="info" size="sm" icon>
                    <i class="text-white fa fa-edit"></i>
                  </base-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Assessment">
              <template v-slot:default="props">
                <div class="d-block text-center">
                  <base-button v-if="props.row.assessment" :loading="props.row.loading" @click="model = props.row;
                   model.assessment = props.row.assessment || {valid:false,sufficient:false};
                    showAssessmentModal = true" class="like btn-link text-white" :type="props.row.assessment.valid ? props.row.assessment.sufficient ? 'green' : 'orange' : 'red'" size="sm" icon>
                    <span>{{ props.row.assessment.valid ? props.row.assessment.sufficient ? 'Sufficient' : 'Insufficient' : 'Invalid' }}</span>
                  </base-button>
                  <base-button v-else :loading="props.row.loading" @click="model = props.row;
                   model.assessment = props.row.assessment || {valid:false,sufficient:false};
                    showAssessmentModal = true" class="like btn-link bg-indigo text-white" type="purple" size="sm" icon>
                    <span>Assess</span>
                  </base-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="View">
              <template v-slot:default="props">
                <div class="d-block text-center">
                  <base-button :loading="props.row.loading" @click="handleView(props.row)" class="like btn-link" type="default" size="sm" icon>
                    <i class="text-white fa fa-eye"></i>
                  </base-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template v-slot:footer>
          <div v-if="!loading" class="col-12  d-flex justify-content-center justify-content-sm-between flex-wrap">
            <!-- <div class="col-sm-6">
                            <base-button @click="handleDelete(props.$index, props.row)" class="like btn-link" type="danger" size="sm" icon>
                                        Delete 
                                    </base-button>
                        </div> -->
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} log sheets
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model:value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total" @update:value="loadTable">
            </base-pagination>
          </div>
        </template>
      </card>
    </div>
  </div>

</div>
</template>

<script>
import Card from '../../components/Card'
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import PortfolioService from "@/api/services/portfolio.service";
import PortfolioItemService from "@/api/services/portfolio-item.service";
import BaseInput from '@/components/BaseInput.vue';
import FormMessage from "../../components/FormMessage.vue";
import BaseCheckbox from "../../components/BaseCheckbox.vue";

export default {
  components: {
    Card,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    BaseInput,
    FormMessage,
    BaseCheckbox
  },
  data() {
    return {
      upload: {
        loading: false,
        message: "",
        messageType: ""
      },
      id: "",
      portfolio: {},
      showPhotoModal: false,
      showAddModal: false,
      showViewModal: false,
      showTrackingModal: false,
      showAssessmentModal: false,
      showEvidenceModal: false,
      showVideoEvidenceModal: false,
      addingPortfolioItem: false,
      uploadingEvidence: false,
      uploadingVideoEvidence: false,
      model: {
        type: '',
        dataType: '',
        timeSpan: '',
      },
      selection: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [1, 2, 3, 10, 25, 50, 100, 250, 500],
        total: 0,
      },
      loaded: false,
      loading: true,
      query: "",
      searchQuery: "",
      propsToSearch: ["title", "dataType", "centre"],
      tableColumns: [{
          prop: "title",
          label: "Activity Title",

        },
        {
          prop: "logDate",
          label: "Date",
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      logBook: {},
      assessment: {},

    };
  },
  computed: {
    pagedData() {
      return this.tableData;
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */

    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = (row[key] + "").toLowerCase().toString();
          if (
            rowValue.includes &&
            rowValue.includes(this.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.pagination.total < highBound) {
        highBound = this.pagination.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    downloadTemplateLink() {
      return TraineeService.getDownloadTemplateLink();
    },
    user() {
      return JSON.parse(window.localStorage.getItem("auth")).user;
    },

  },
  methods: {
    async loadTable() {

      this.loading = true

      if (this.from > this.pagination.total) {
        this.pagination.currentPage = 1;
      }

      var params = this.id + "?size=" + this.pagination.perPage + "&page=" + (this.pagination.currentPage - 1);

      if (this.searchQuery.length > 0) {
        params = params + "&s=" + this.searchQuery;
      }

      var response = await PortfolioItemService.getPortfolioItems(params);

      this.loading = false;
      if (response.data.result != (null || undefined)) {
        this.tableData = response.data.result;
        this.pagination.total = response.data.totalElements
        this.pagination.perPage = response.data.size
        this.pagination.currentPage = response.data.page + 1
      }

      return this.tableData;
    },
    handleView(row) {
      this.model = row;
      this.showViewModal = true;
    },
    handleTrackingView(row) {
      this.model = row;
      if (!this.model.tracks.length) {
        console.log(this.model)
        this.model.tracks.push('');
      }
      this.showTrackingModal = true;
    },
    saveTracking() {
      this.model.savingTracks = true;
      this.showTrackingModal = false;
      const saveTrackingSwal = swal.mixin({
        customClass: {
          confirmButton: "btn btn-default btn-sm btn-fill",
        },
        buttonsStyling: false,
      });
      PortfolioItemService.saveTracks(this.model.id, this.model.tracks).then((response) => {
        saveTrackingSwal.fire({
          text: response.data.message,
          confirmButtonText: 'Continue'
        })
      }).catch((response) => {
        saveTrackingSwal.fire({
          text: error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message,
          confirmButtonText: "Ok",
        });
      }).finally(() => {
        this.model.savingTracks = false;
      })
    },
    assessItem() {
      const assessmentSwal = swal.mixin({
        customClass: {
          confirmButton: "btn btn-default btn-sm btn-fill",
        },
        buttonsStyling: false,
      });

      PortfolioItemService.assessItem(this.model.id, this.model.assessment).then((response) => {
        assessmentSwal.fire({
          text: response.data.message,
          confirmButtonText: "Ok"
        });
        this.loadTable();
      }).catch((error) => {
        assessmentSwal.fire({
          text: error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message,
          confirmButtonText: "Ok"
        });
      }).finally(() => {
        this.addingRemark = false;
      });
    },
  },
  created() {
    if (this.$route.params.id == undefined) {
      this.$router.push("/assessor/portfolios");
      // }else if(this.$route.params.portfolio != undefined){
      //     this.logBook = this.$route.params.portfolio;
    } else {
      this.id = this.$route.params.id;

      PortfolioService.getPortfolioById(this.id).then((response) => {
        this.portfolio = response.data.result;
      }).catch(() => {

      })
      this.loadTable();
    }
  },
  mounted() {},

};
</script>

<style scoped>
.user-img {
  background: linear-gradient(125deg, #efefef, #fff);
  padding: 10px;
}

.el-table {

  overflow-x: scroll;
}

.no-border-card .card-footer {
  border-top: 0;
  border-top: 0;
}

.modal-centre-img {
  border-radius: 100%;
  border: 4px solid rgb(187, 100, 121);
  width: 180px;
  height: 180px;
  object-fit: cover;
  box-shadow: 0px 3px 10px grey !important;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #77132b;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.portfolio-img {
  border: 3px solid #fff;
  width: 180px;
  height: 180px;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background: #efefef;
}

.portfolio-modal-img {
  border: 5px solid #fff;
  width: 280px;
  height: 280px;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background: #efefef;
}

.portfolio-name {
  float: right;

}

.portfolio-name {
  float: right;

}

@media (max-width: 990px) {
  .portfolio-name {
    padding-top: 120px;
    float: none;
  }
}
</style>
