<template>
<div class="form-group">
  <label class="form-control-label">
    {{label}} <span class="text-danger" v-if="required">*</span>
  </label>
  <select :required="required || undefined" ref="select" multiple class="form-control-alternative form-control">
    <slot></slot>
  </select>
</div>
</template>

<script>
import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";

export default {
  name: "selects",
  props: ["options", "values", "label", "optionValueKey", "optionTextKey", "max", "single", "required"],
  mounted() {
    this.setChoices();
  },
  emits: ['update:values'],
  methods: {
    add(e) {
      
      if(this.single){
        this.$emit("update:values", e.detail.value)
      }else{
        for(let i = 0; i < this.values.length; i++) {
          if(this.values[i] == e.detail.value){
            this.values.splice(i, 1);
          }
        }
        this.values.push(e.detail.value);
        this.$emit("update:values", this.values);
      }
      
      
      // this.$emit("update:values", this.single ? this.values[0] : this.values);
    },

    remove(e) {
      if(this.single){
        this.$emit("update:values", undefined);
      }else{
        for(let i = 0; i < this.values.length; i++) {
          if(this.values[i] == e.detail.value){
            this.values.splice(i, 1);
          }
        }
        this.$emit("update:values", this.values)
      }
    },
    
    setChoices() {

      this.choicesInstance = new Choices(this.$refs.select, {
        removeItemButton: true,
        editItems: false,
        delimiter: ",",
        maxItemCount: this.single ? 1 : this.max,
      });

      this.$refs.select.addEventListener("addItem", this.add);
      this.$refs.select.addEventListener("removeItem", this.remove);

      this.choicesInstance.setChoices(this.options, this.optionValueKey || "value", this.optionTextKey || "text", true);
      if(this.values == undefined){
        return;
      }

      if(this.single){
        this.choicesInstance.setChoiceByValue(this.values)
      }else {
        this.values.forEach((value) => {
          this.choicesInstance.setChoiceByValue(value);
        })
      }
      // const values = this.choicesInstance.getValue(true)

      // this.$emit("update:values", this.single? values[0] : values);
    }
  },
  watch: {
    // whenever question changes, this function will run
    options(newQuestion, oldQuestion) {
      this.choicesInstance.destroy();
      this.setChoices()
    }
  },
  unmounted() {
    this.choicesInstance.destroy();
  }
};
</script>

<style>
.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #fff;
  padding: 7.5px 7.5px 3.75px;
  border-radius: 8px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow 0.15s ease;
}

.choices__input {
  background: #fff;
}

.choices__list--multiple .choices__item {
  background-color: #77132b;
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  border-left: none;
}

.choices__list--dropdown {
  background-color: #fffdfd;
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  z-index: 4999;
}
</style>
