import DashboardLayout from "@/layout/DashboardLayout";
import Dashboard from "../views/external_verifier/Dashboard.vue";
import Portfolio from "../views/external_verifier/Portfolio.vue";
import Portfolios from "../views/external_verifier/Portfolios.vue";
import Assessor from "../views/external_verifier/Assessor.vue";
import Assessors from "../views/external_verifier/Assessors.vue";
import InternalVerifier from "../views/external_verifier/InternalVerifier.vue";
import InternalVerifiers from "../views/external_verifier/InternalVerifiers.vue";
import Requests from "../views/external_verifier/Requests.vue";
import Invitations from "../views/external_verifier/Invitations.vue";
import Centres from "../views/external_verifier/Centres.vue";
import Verifications from "../views/external_verifier/Verifications.vue";


export default {
    path: "/external-verifier/",
    component: DashboardLayout,
    children: [
        {
            path: "dashboard",
            name: "external-verifier-dashboard",
            components: { default: Dashboard },
        },
        {
            path: "portfolio/:id",
            name: "external-verifier-portfolio",
            components: { default: Portfolio },
        },
        {
            path: "portfolios",
            name: "external-verifier-portfolios",
            components: { default: Portfolios },
        },
        {
            path: "assessor/:id",
            name: "external-verifier-assessor",
            components: { default: Assessor },
        },
        {
            path: "assessors",
            name: "external-verifier-assessors",
            components: { default: Assessors },
        },
        {
            path: "internal-verifier/:id",
            name: "external-verifier-internal-verifier",
            components: { default: InternalVerifier },
        },
        {
            path: "internal-verifiers",
            name: "external-verifier-internal-verifiers",
            components: { default: InternalVerifiers },
        },
        {
            path: "requests",
            name: "external-verifier-requests",
            components: { default: Requests },
        },
        {
            path: "exvitations",
            name: "external-verifier-invitations",
            components: { default: Invitations },
        },
        {
            path: "centres",
            name: "external-verifier-centres",
            components: { default: Centres },
        },
        {
            path: "Verifications",
            name: "external-verifier-verifications",
            components: { default: Verifications },
        },
    ]
}