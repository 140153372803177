<template>
<div class="content">
  <base-header class="header pb-8 pt-5 pt-md-6 d-flex align-items-center" style="
        background-image: url(/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
    <!-- Mask -->
    <span class="mask bg-gradient-default opacity-95"></span>
    <!-- Header container -->
    <div class="container-fluid d-flex align-items-center mt-5">
      <div class="row">
        <div class="col-lg-7 col-md-10">
          <p class="text-white mt-0 mb-3">
            <!-- This is your profile page. You can see the progress you've made
              with your work and manage your projects or assigned tasks -->
          </p>
        </div>
      </div>
    </div>
  </base-header>
  <div class="container-fluid mt--7">

    <div>
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <template v-slot:header>
          <div class="row align-items-center">
            <div class="col-sm-4 d-flex">
              <h3 class="mb-0">Assessors </h3>
            </div>
            <div class="col-sm-8 text-right">
              <base-button :loading="loading" @click="loadTable" type="dark">
                <i class="fa fa-sync"> </i>
              </base-button>
            </div>
          </div>
        </template>
        <div class="px-3">
          <form-message :type="upload.messageType" v-model:message="upload.message"></form-message>
        </div>
        <div class="px-3">
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select @change="loadTable" class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>

            <div class="mb-3 col-sm-5 col-lg-4">
              <div class="row">
                <div class="form-group col-10">
                  <label class="form-control-label">Select Programme</label>
                  <select @change="loadTable" class="form-control form-control-alternative" v-model="selectedProgramme">
                    <option v-for="option in programmes" :value="option.value">{{option.text}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <el-input type="search" class="col-10" clearable placeholder="Filter Records" v-model="searchQuery" aria-controls="datatables">
                </el-input>
                <base-button type="default" @click="loadTable">
                  <i class="fa fa-search"></i>
                </base-button>
              </div>
            </div>
          </div>
          <div v-if="loading" class="text-center">
            <div class="lds-grid">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <el-table v-show="!loading" :data="tableData" row-key="id" class="px-3" header-row-class-name="thead-light">
            <!-- <el-table-column label="Select">
                            <template v-slot:default="props">
                                <input class="ml-3" type="checkbox" v-model="selection" :value="props.row.id">
                            </template>
                        </el-table-column> -->
            <el-table-column width="70" align="left" label="Photo">
              <template v-slot:default="props">
                <img :src="props.row.photoUrl || '/img/theme/user.jpg'" style="border: 2px solid #77132b; border-radius: 50%; object-fit: cover; width: 50px !important; height: 50px !important;">
              </template>
            </el-table-column>
            <el-table-column align="left" label="Name">
              <template v-slot:default="props">
                <div class="d-flex">
                  <router-link class="text-dark text-bold" :to="{ name: 'centre-assessor', params: { id: props.row.id } }">
                    <strong>{{props.row.name}}</strong>
                  </router-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column" sortable>
            </el-table-column>
            <el-table-column align="center" label="Actions">
              <template v-slot:default="props">
                <div class="d-block text-center">
                  <base-button :loading="props.row.loading" class=" bg-gradient-seagreen btn " text-color="white" type="success" size="sm" icon>
                    Preview
                  </base-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template v-slot:footer>
          <div v-if="!loading" class="col-12  d-flex justify-content-center justify-content-sm-between flex-wrap">
            <!-- <div class="col-sm-6">
                            <base-button @click="handleDelete(props.$index, props.row)" class="like btn-link" type="danger" size="sm" icon>
                                        Delete 
                                    </base-button>
                        </div> -->
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} assessors.
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model:value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total" @update:value="loadTable">
            </base-pagination>
          </div>
        </template>
      </card>
    </div>
  </div>

</div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import AssessorService from "@/api/services/assessor.service";
import ProgrammeService from "@/api/services/programme.service";
import BaseInput from '@/components/BaseInput.vue';
import FormMessage from "../../components/FormMessage.vue";
import BaseCheckbox from "../../components/BaseCheckbox.vue";

export default {
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    BaseInput,
    FormMessage,
    BaseCheckbox
  },
  data() {
    return {
      upload: {
        loading: false,
        message: "",
        messageType: ""
      },
      id: "",
      selectedProgramme: null,
      showAddModal: false,
      showViewModal: false,
      showEvidenceModal: false,
      showVideoEvidenceModal: false,
      showRemarkModal: false,
      addingAssessor: false,
      uploadingEvidence: false,
      uploadingVideoEvidence: false,
      model: {
        type: '',
        dataType: '',
        timeSpan: '',
      },
      selection: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [1, 2, 3, 10, 25, 50, 100, 250, 500],
        total: 0,
      },
      loaded: false,
      loading: true,
      query: "",
      searchQuery: "",
      propsToSearch: ["searchString"],
      tableColumns: [{
        prop: "email",
        label: "Email"
      },{
        prop: "phone",
        label: "Phone Number"
      }],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      programmes: [],
      remark: {},

    };
  },
  computed: {
    pagedData() {
      return this.tableData;
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */

    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = (row[key] + "").toLowerCase().toString();
          if (
            rowValue.includes &&
            rowValue.includes(this.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.pagination.total < highBound) {
        highBound = this.pagination.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    user() {
      return JSON.parse(window.localStorage.getItem("auth")).user;
    },

  },
  methods: {
    async loadTable() {

      this.loading = true

      if (this.from > this.pagination.total) {
        this.pagination.currentPage = 1;
      }

      var params = this.id + "?size=" + this.pagination.perPage + "&page=" + (this.pagination.currentPage - 1);

      if (this.searchQuery.length > 0) {
        params = params + "&s=" + this.searchQuery;
      }

      let response;

      try {
        response = await AssessorService.getAssessorsByCentreProgramme(this.selectedProgramme, params);
      } catch (error) {} finally {
        this.loading = false;
      }

      console.log(response);
      if (response.data.result) {
        this.tableData = response.data.result;
        this.pagination.total = response.data.totalElements
        this.pagination.perPage = response.data.size
        this.pagination.currentPage = response.data.page + 1
      }

      return this.tableData;
    },

    getAssignedButtonColor(assigned) {
      return assigned ? assigned == this.user.email ? 'bg-indigo' : 'bg-dark' : 'bg-warning';
    }
  },
  created() {
    ProgrammeService.getCentreProgrammes().then((response) => {

      if (response.data.result.length == 0) {
        this.loading = false;
        return;
      }
      this.programmes = response.data.result.map((programme) => ({
        text: programme.title,
        value: programme.id,
      }));

      this.selectedProgramme = response.data.result[0].id
      this.loadTable();
    });

  },
  mounted() {},

};
</script>

<style scoped>
.el-table {
  overflow-x: scroll;
}

.no-border-card .card-footer {
  border-top: 0;
  border-top: 0;
}

.modal-centre-img {
  border-radius: 100%;
  border: 4px solid rgb(187, 100, 121);
  width: 180px;
  height: 180px;
  object-fit: cover;
  box-shadow: 0px 3px 10px grey !important;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #77132b;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}
</style>
