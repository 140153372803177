<template>
<div class="row justify-content-center">
  <div class="col-lg-5 col-md-7">
    <div class="card bg-secondary shadow border-0">
      <div class="card-header bg-transparent pb-5">
        <div class="text-default text-center mt-2">
          <h2 class="text-default">Welcome to E-limi</h2>
          <small><strong>Sign in to the Assessment Portal</strong></small>
        </div>
      </div>
      <div class="card-body px-lg-5 py-lg-5">
        <form-message v-model:type="messageType" v-model:message="message"></form-message>

        <form role="form" @submit.prevent="handleLogin()">
          <base-input :required="true" type="text" formClasses="input-group-alternative mb-3" placeholder="Email address / Phone number" addon-left-icon="ni ni-single-02" :diasbled="loading" v-model:value="model.email">
          </base-input>

          <base-input :required="true" type="password" formClasses="input-group-alternative mb-3" placeholder="Password" addon-left-icon="ni ni-lock-circle-open" :disabled="loading" v-model:value="model.password">
          </base-input>

          <div class="text-center">
            <base-button type="default" native-type="submit" v-model:loading="loading" class="my-4">
              Sign in
            </base-button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <router-link to="/forgot-password" class="text-dark">
          <strong class="text-dark text-sm">
            Forgot password?
          </strong>
        </router-link>
      </div>
      <div class="col-6 text-right">
        <router-link to="/register">
          <strong class="text-dark text-sm">
            Create new account
          </strong>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import FormMessage from "@/components/FormMessage.vue";

import AuthService from "@/api/services/auth.service";

import config from "@/api/config";

var API_URL = config.API_URL;
var ENV_URL = config.ENV_URL;
var BASE_ENV_URL = config.BASE_ENV_URL;

export default {
  name: "Login",
  components: {
    FormMessage,
  },
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      message: "",
      messageType: "",
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    successFn(data) {
      if (data.accessToken != null) {
        window.localStorage.setItem("auth", JSON.stringify(data));
        this.$router.push("/profile");
      }
      this.loading = null;
      this.message = response.data.message;
      this.messageType = "success";
    },
    failureFn(error) {
      this.loading = null;
      this.message =
        error.response == undefined ?
        "Something went wrong. <br> Please check your network and try again." :
        error.response.data.message;
      this.messageType = "danger";
    },
    handleLogin() {
      this.loading = true;
      this.submitted = true;

      var obj = {
        model: this.model,
        success: this.successFn,
        failure: this.failureFn,
      };

      if (this.model.email && this.model.password) {
        this.$store.dispatch("auth/login", obj);
      }
    },
  },
  mounted() {
    // if (this.$route.query.tsk != undefined) {
    //   console.log(this.$route);
    //   var tsk = this.$route.query.tsk;
    //   AuthService.login({ tsk })
    //     .then((response) => {
    //       window.localStorage.setItem("auth", JSON.stringify(response.data));
    //       this.$router.push("/profile");
    //     })
    //     .catch(() => {
    //       window.location = `${BASE_ENV_URL}login`;
    //     });
    // } else if (this.loggedIn) {
    //   this.$router.push("/profile");
    // } else {
    //   window.location = `${BASE_ENV_URL}login`;
    // }
  },
};
</script>

<style></style>
