// export configurations to be used globally

// http://localhost:8000/api/v1/
// https://api.e-limi.africa/v1/

export default {
  // API_URL: "https://nsq.e-limi.africa:8000/api/v1/",
  // API_URL: "http://66.29.134.123:8000/api/v1/",
  // API_URL: "http://localhost:8000/api/v1/",
  API_URL: process.env.API_URL || "http://api.e-limi.africa:8001/api/v1/",
  // API_URL: process.env.API_URL || "http://nsqapi.e-limi.africa/api/v1/",
  // API_URL: process.env.API_URL || "http://127.0.0.1:8001/api/v1/",
  // ENV_URL: process.env.ENV_URL, //https://nsq.e-limi.africa/",
  // BASE_ENV_URL: process.env.BASE_ENV_URL, //"https://dev.e-limi.africa/portal/"
};
