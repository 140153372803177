<template>
<div>
  <base-header class="header pb-8 pt-5 pt-md-6 d-flex align-items-center" style="
        background-image: url(/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
    <!-- Mask -->
    <span class="mask bg-gradient-default opacity-95"></span>
    <!-- Header container -->
    <div class="row my-5 pt-5">
      <router-link to="/centre/portfolios" class="col-xl-3 col-lg-6">
        <stats-card title="Trainees" type="gradient-seagreen" :sub-title="`${centre.traineeCount}`" icon="ni ni-hat-3" class="mb-4 mb-xl-0">
        </stats-card>
      </router-link>
      <router-link to="/centre/assessors" class="col-xl-3 col-lg-6">
        <stats-card title="Assessors" type="gradient-info" :sub-title="`${centre.assessorCount}`" icon="ni ni-badge" class="mb-4 mb-xl-0">
        </stats-card>
      </router-link>
      <router-link to="/centre/internal-verifiers" class="col-xl-3 col-lg-6">
        <stats-card title="Verifiers" type="gradient-purple" :sub-title="`${centre.internalVerifierCount}`" icon="ni ni-check-bold" class="mb-4 mb-xl-0">
        </stats-card>
      </router-link>
      <div class="col-xl-3 col-lg-6">
        <stats-card title="Assessments" type="gradient-indigo" :sub-title="`${centre.assessmentCount}`" icon="ni ni-bullet-list-67" class="mb-4 mb-xl-0">
        </stats-card>
      </div>
    </div>
  </base-header>
  <div class="container-fluid mt--7">

    <div class="row mt-5">
      <div class="col-xl-12 order-xl-1">
        <card shadow type="secondary" class="mb-3">
          <template v-slot:header>
            <div class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">My Centre</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button :loading="loading" class="float-right pull-right" @click="handleEdit()" type="primary" size="sm">{{edit? 'Save' : 'Edit'}}</base-button>
              </div>
              </div>
            </div>
          </template>

          <div class="row">
            <div class="col-lg-12 mb-4">
              <div class="card shadow">
                <div class="card-body">
                  <!--  -->
                  <div class="row">
                    <div class="col-12">
                      <form-message :message="formMessage" :type="formMessageType"></form-message>
                    </div>
                    <div class="col-md-12" v-if="!edit">
                      <div class="px-2 py-3">
                        <form class="pl-lg-4">
                          <div class="row">
                            <div class="col-sm-8 my-3">
                              <div class="form-group">
                                <strong>Name </strong>
                                <p>{{ centre.name }}</p>
                              </div>
                            </div>
                            <div class="col-sm-4 my-3">
                              <div class="form-group">
                                <strong>Capacity </strong>
                                <p>{{ centre.capacity }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-8 my-3">
                              <div class="form-group">
                                <strong>Location </strong>
                                <p> {{ centre.address }} </p>
                              </div>
                            </div>
                            <div class="col-sm-4 my-3">
                              <div class="form-group">
                                <strong>State </strong>
                                <p>{{ centre.countryState }}</p>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div class="col-md-12" v-if="edit">
                      <div class="px-2 py-3">
                        <form class="pl-lg-4" @submit.prevent="saveEdit()">
                          <div class="row">
                            <div class="col-sm-8 my-3">
                              <base-input required :disabled="loading" alternative="" label="Name" placeholder="Name" input-classes="form-control-alternative" v-model:value="model.name" />
                            </div>
                            <div class="col-sm-4 my-3">
                              <base-input required :disabled="loading" alternative="" type="number" min="10" max="12000" label="Capacity" placeholder="No of students" input-classes="form-control-alternative" v-model:value="model.capacity" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-8 my-3">
                              <base-input required :disabled="loading" alternative="" label="Location" placeholder="Address, City" input-classes="form-control-alternative" v-model:value="model.address" />
                            </div>
                            <div class="col-sm-4 my-3">
                              <div class="form-group">
                                <label class="form-control-label">
                                  State <span class="text-danger">*</span>
                                </label>
                                <select required :disabled="loading" class="form-control-alternative form-control" v-model="model.countryState" style="width: 100%">
                                  <option v-for="state in states" :key="state" :value="state">
                                    {{ state }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-8 my-3">
                              <MultipleSelect :options="programmes" label="NSQ Programmes" v-model:values="model.programmeIds" optionValueKey="id" optionTextKey="title"></MultipleSelect>
                            </div>
                            <div class="col-sm-4 my-3">
                              <div class="form-group">
                                <label class="form-control-label">
                                  Activtiy State <span class="text-danger">*</span>
                                </label>
                                <div class="form-control-alternative form-control">
                                  <div class="row">
                                    <label class="col-6 col-sm-7 col-md-8 form-control-label">{{model.active ? "Active" : "Inactive"}}</label>
                                    <base-switch class="col-6 col-sm-5 col-md-4" v-model:value="model.active"></base-switch>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-6 my-3">
                              <div class="card shadow">
                                <div class="card-header text-muted text-small">
                                  <strong>Auto Accept Assessors</strong>
                                </div>
                                <div class="card-body">
                                  <div class="row">
                                    <label class="col-9 form-control-label">{{model.autoAcceptAssessor ? "Yes" : "No"}}</label>
                                    <base-switch class="col-3" v-model:value="model.autoAcceptAssessor"></base-switch>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6 my-3">
                              <div class="card shadow">
                                <div class="card-header text-muted text-small">
                                  <strong>Auto Assign Assessors</strong>
                                </div>
                                <div class="card-body">
                                  <div class="row">
                                    <label class="col-9 form-control-label">{{model.autoAssignAssessor ? "Yes" : "No"}}</label>
                                    <base-switch class="col-3" v-model:value="model.autoAssignAssessor"></base-switch>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-6 my-3">
                              <div class="card shadow">
                                <div class="card-header text-muted text-small">
                                  <strong>Auto Accept Verifiers</strong>
                                </div>
                                <div class="card-body">
                                  <div class="row">
                                    <label class="col-9 form-control-label">{{model.autoAcceptInternalVerifier ? "Yes" : "No"}}</label>
                                    <base-switch class="col-3" v-model:value="model.autoAcceptInternalVerifier"></base-switch>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6 my-3">
                              <div class="card shadow">
                                <div class="card-header text-muted text-small">
                                  <strong>Auto Assign Verifiers</strong>
                                </div>
                                <div class="card-body">
                                  <div class="row">
                                    <label class="col-9 form-control-label">{{model.autoAssignInternalVerifier ? "Yes" : "No"}}</label>
                                    <base-switch class="col-3" v-model:value="model.autoAssignInternalVerifier"></base-switch>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button type="submit" class="btn btn-block btn-primary d-sm-none" ref="editButton"></button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import Chart from "chart.js";

import FormMessage from '@/components/FormMessage'
import MultipleSelect from '@/components/MultipleSelect'
import CentreService from "@/api/services/centre.service";
import ProgrammeService from "@/api/services/programme.service";

let chart;

export default {
  components: {
    FormMessage,
    MultipleSelect,
  },
  name: "centres-by-admin",
  data() {
    return {
      profiles: [],
      failedNetworkModal: false,
      centre: {},
      model: {},
      edit: false,
      loading: true,
      programmes: [],
      states: [
        "ABIA",
        "ADAMAWA",
        "AKWA",
        "ANAMBRA",
        "BAUCHI",
        "BAYELSA",
        "BENUE",
        "BORNO",
        "CROSS",
        "DELTA",
        "EBONYI",
        "EDO",
        "EKITI",
        "ENUGU",
        "FCT",
        "GOMBE",
        "IMO",
        "JIGAWA",
        "KADUNA",
        "KANO",
        "KATSINA",
        "KEBBI",
        "KOGI",
        "KWARA",
        "LAGOS",
        "NASSARAWA",
        "NIGER",
        "OGUN",
        "ONDO",
        "OSUN",
        "OYO",
        "PLATEAU",
        "RIVERS",
        "SOKOTO",
        "TARABA",
        "YOBE",
        "ZAMFARA",
      ],
      formMessage: "",
      formMessageType: "",
    };
  },
  methods: {
    reload() {
      window.location.reload();
    },

    handleEdit() {
      if (this.edit) {
        this.$refs.editButton.click()
      }
      this.edit = true;
    },

    saveEdit() {
      this.loading = true;
      CentreService.updateCentre(this.model).then((response) => {

        this.centre = response.data.result;
        this.model = this.centre;

        this.formMessage = response.message;
        this.formMessageType = "success";

      }).catch((error) => {
        this.formMessage = error.response == undefined ?
          "Something went wrong. <br> Please check your network and try again." : error.response.data.message;
        this.formMessageType = "danger";
      }).finally(() => {
        this.loading = false;
        this.edit = false;
      });
    },

  },

  mounted() {
    CentreService.getCentre().then((response) => {

      this.centre = response.data.result;
      this.model = {
        ...response.data.result,
        programmes: []
      }

    }).catch(() => {
      this.failedNetworkModal = true;
    }).finally(() => {
      this.loading = false;
    })

    ProgrammeService.getProgrammes().then((response) => {
      this.programmes = response.data.result;
    }).catch(() => {
      this.failedNetworkModal = true;
    })
  },
};
</script>

<style></style>
