<template>
<div class="content">
  <modal v-model:show="showAddModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-4" class="border-0">
      <template v-slot:header>
        <div class="text-muted text-left mb--5">
          <h3>Request To Verify For Centre </h3>
        </div>
      </template>
      <form role="form" @submit.prevent="addRequest(); showAddModal = false;">

        <div class="">
          <div class="row">
            <div class="col-sm-12">
              <multiple-select :required="true" :options="programmes" v-model:values="model.programmeId" :single="true" label="Programme" @update:values="getCentres();"></multiple-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <multiple-select :required="true" :disabled="centres == [] ? true : undefined" v-if="model.programmeId != undefined" :options="centres" v-model:values="model.centreId" :single="true" label="Centre"></multiple-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <base-input v-if="model.programmeId != undefined" alternative="" label="Message">
                <textarea :rows="3" v-model="model.message" class="form-control form-control-alternative" placeholder="Brief message to let the centre know why you should verify for them."></textarea>
              </base-input>
            </div>
          </div>

          <button class="d-none" ref="addRequestFormButton" type="submit"></button>
        </div>
      </form>
      <template v-slot:footer>
        <div class="">
          <base-button type="warning" @click="showAddModal = false" class="my-1">
            Close
          </base-button>
          <base-button type="info" @click="$refs.addRequestFormButton.click();" class="my-1 float-right">
            Save
          </base-button>
        </div>
      </template>
    </card>
  </modal>

  <base-header class="header pb-8 pt-5 pt-md-6 d-flex align-items-center" style="
        background-image: url(/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      ">
    <!-- Mask -->
    <span class="mask bg-gradient-default opacity-95"></span>
    <!-- Header container -->
    <div class="container-fluid d-flex align-items-center mt-5">
      <div class="row">
        <div class="col-lg-7 col-md-10">
          <p class="text-white mt-0 mb-3">
            <!-- This is your profile page. You can see the progress you've made
              with your work and manage your projects or assigned tasks -->
          </p>
        </div>
      </div>
    </div>
  </base-header>
  <div class="container-fluid mt--7">

    <div>
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <template v-slot:header>
          <div class="row align-items-center">
            <div class="col-sm-4 d-flex">
              <h3 class="mb-0">Verification Requests To Centres </h3>
            </div>
            <div class="col-sm-8 text-right">
              <base-button :loading="showAddModal || addingRequest" @click="model = {}; showAddModal = true" class="btn-sm mr-5">
                <i class="fa fa-plus"></i>
                Request
              </base-button>
              <base-button @click="loadTable" :loading="loading" type="dark">
                <i class="fa fa-sync"> </i>
              </base-button>
            </div>
          </div>
        </template>
        <div class="px-3">
          <form-message :type="upload.messageType" v-model:message="upload.message"></form-message>
        </div>
        <div class="px-3">
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select @change="loadTable" class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Per page">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                </el-option>
            </el-select>

            <div class="mb-3 col-sm-5 col-lg-4">
              <div class="row" v-show="false">
                <el-input type="search" class="col-10" clearable placeholder="Filter Records" v-model="searchQuery" aria-controls="datatables">
                </el-input>
                <base-button type="default" @click="loadTable">
                  <i class="fa fa-search"></i>
                </base-button>
              </div>
            </div>
          </div>
          <div v-if="loading" class="text-center">
            <div class="lds-grid">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <el-table v-show="!loading" :data="tableData" row-key="id" class="px-3" header-row-class-name="thead-light">
            <!-- <el-table-column label="Select">
                            <template v-slot:default="props">
                                <input class="ml-3" type="checkbox" v-model="selection" :value="props.row.id">
                            </template>
                        </el-table-column> -->
            <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column" sortable>
            </el-table-column>
            <el-table-column max-width="60px" align="center" label="Status">
              <template v-slot:default="props">
                <div class="d-block text-center">
                  <base-button class="like btn-link" :type="props.row.acceptDate == null? 'warning': 'success'" :title="props.row.acceptDate" size="sm" icon>
                      <span class="text-white">{{props.row.acceptDate == null? 'Not Accepted': 'Accepted'}}</span>
                  </base-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="50px" align="left" label="Revoke">
              <template v-slot:default="props">
                <div class="d-flex">
                  <base-button :loading="props.row.loading" @click="handleDelete(props.$index, props.row)" class="like btn-link" type="danger" size="sm" icon>
                    <i class="text-white fa fa-times"></i>
                  </base-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template v-slot:footer>
          <div v-if="!loading" class="col-12  d-flex justify-content-center justify-content-sm-between flex-wrap">
            <!-- <div class="col-sm-6">
                            <base-button @click="handleDelete(props.$index, props.row)" class="like btn-link" type="danger" size="sm" icon>
                                        Delete 
                                    </base-button>
                        </div> -->
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} requests to centres
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model:value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total" @update:value="loadTable">
            </base-pagination>
          </div>
        </template>
      </card>
    </div>
  </div>

</div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import swal from "sweetalert2";
import MultipleSelect from '../../components/MultipleSelect'
import BasePagination from "@/components/BasePagination";
import ProgrammeService from "@/api/services/programme.service";
import CentreService from "@/api/services/centre.service";
import InternalVerifierService from "@/api/services/internal-verifier.service";
import BaseInput from '@/components/BaseInput.vue';
import FormMessage from "../../components/FormMessage.vue";
import BaseCheckbox from "../../components/BaseCheckbox.vue";

export default {
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    BaseInput,
    FormMessage,
    BaseCheckbox,
    MultipleSelect,
  },
  data() {
    return {
      centres: [],
      _centres: new Set(),
      programmes: [],
      _programmes: new Set(),
      upload: {
        loading: false,
        message: "",
        messageType: ""
      },
      id: "",
      showAddModal: false,
      model: {
        centres: [],
      },
      selection: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 250, 500],
        total: 0,
      },
      loaded: false,
      loading: true,
      addingRequest: false,
      query: "",
      searchQuery: "",
      propsToSearch: ["title", "dataType", "timeSpan"],
      tableColumns: [{
          prop: "centreName",
          label: "Centre",
          minWidth: 100,
        },
        {
          prop: "programmeTitle",
          label: "Programme",
          minWidth: 60,
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      addModel: {},
      remark: {},

    };
  },
  computed: {
    pagedData() {
      return this.tableData;
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */

    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = (row[key] + "").toLowerCase().toString();
          if (
            rowValue.includes &&
            rowValue.includes(this.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.pagination.total < highBound) {
        highBound = this.pagination.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    user() {
      return JSON.parse(window.localStorage.getItem("auth")).user;
    },

  },
  methods: {

    getCentres() {
      if (this.model.programmeId) {
        CentreService.getCentresByProgramme(this.model.programmeId).then((response) => {
          this.centres = response.data.result.map((centre) => ({
            value: centre.id,
            text: centre.name,
          }));
          response.data.result.forEach((centre) => this._centres.add(centre));
        }).catch(() => {});
      }
    },

    async loadTable() {

      this.loading = true

      if (this.from > this.pagination.total) {
        this.pagination.currentPage = 1;
      }

      var params = this.id + "?size=" + this.pagination.perPage + "&page=" + (this.pagination.currentPage - 1);

      if (this.searchQuery.length > 0) {
        params = params + "&s=" + this.searchQuery;
      }

      let response;
      
      try { response = await await InternalVerifierService.getRequests(params); } catch (error) {} finally {
          this.loading = false;
      }
      
      if (response.data.result != (null || undefined)) {
        this.tableData = response.data.result;
        this.pagination.total = response.data.totalElements
        this.pagination.currentPage = response.data.page + 1
      }

      return this.tableData;
    },

    addRequest() {

      this.addingRequest = true;

      const addSwal = swal.mixin({
        customClass: {
          confirmButton: "btn btn-default btn-sm btn-fill",
        },
        buttonsStyling: false,
      });

      InternalVerifierService.sendRequest(this.model)
        .then((response) => {
          addSwal.fire({
            text: response.data.message,
            confirmButtonText: "Ok",
          });
          
          this.tableData.push(response.data.result);
          this.pagination.total = response.data.result.length
          
          this.loadTable();
        }).catch((error) => {
          addSwal.fire({
            text: error.response == undefined ? "Something went wrong. <br> Please check your network and try again." : error.response.data.message,
            confirmButtonText: "Ok",
          });
        }).finally(() => {
          this.addingRequest = false;
        })
    },
    handleView(row) {
      this.model = row;
      this.showViewModal = true;
    },

    handleDelete(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          text: `Deleting request to verify under ${row.centreName}`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Deleted!",
              html: `You deleted your request to <strong>${row.centreName}</strong> under the <strong>${row.programmeTitle}</strong>`,
            });
          }
        });
    },

    deleteRow(row) {
      row.loading = true;

      InternalVerifierService.deleteRequest(row.id).then(() => {

        this.loadTable();

      }).catch(() => {}).finally(() => {

        row.loading = false;
      });
    },
  },
  created() {
    ProgrammeService.getProgrammes().then((response) => {
      this.programmes = response.data.result.map((programme) => ({
        value: programme.id,
        text: programme.title,
      }));
      response.data.result.forEach((programme) => this._programmes.add(programme));
    }).catch(() => {});
    this.loadTable();
  },
  mounted() {},

};
</script>

<style scoped>
.no-border-card .card-footer {
  border-top: 0;
  border-top: 0;
}

.modal-centre-img {
  border-radius: 100%;
  border: 4px solid rgb(187, 100, 121);
  width: 180px;
  height: 180px;
  object-fit: cover;
  box-shadow: 0px 3px 10px grey !important;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #77132b;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}
</style>
