export default {
    PUBLIC: ["login", "register", "register-by-role", "forgot-password", "password-reset", "icons"],
    PROTECTED: ["account", "notifications", "profile", "log-book-view", "assessor-invitation"],
    TRAINEE: ["trainee", "trainee-portfolio", "trainee-portfolios"],
    SUPERVISOR: ["portfolios", "supervisor", "", "", ""],
    ASSESSOR: ["portfolios", "assessor", "assessor-portfolio", "assessor-portfolios", "assessor-invitation", "assessor-requests", "assessor-invitations", "assessor-centres", "assessor-assessments"],
    INTERNAL_VERIFIER: ["portfolios", "internal-verifier", "internal-verifier-portfolio", "internal-verifier-portfolios", "internal-verifier-assessor", "internal-verifier-assessors", "internal-verifier-invitation", "internal-verifier-requests", "internal-verifier-invitations", "internal-verifier-centres", "internal-verifier-verifications"],
    CENTRE_ADMIN: ["centre-dashboard", "centre-portfolios", "centre-portfolio", "centre-assessors", "centre-assessor", "centre-internal-verifiers", "centre-internal-verifier", "centre-administrator"],
    EXTERNAL_VERIFIER: ["external-verifier-dashboard", "external-verifier", "external-verifier-portfolio", "external-verifier-portfolios", "external-verifier-assessors", "external-verifier-assessors", "external-verifier-internal-verifier", "external-verifier-internal-verifiers", "external-verifier-invitation", "external-verifier-requests", "external-verifier-invitations", "external-verifier-centres", "external-verifier-verifications"],
    PROGRAMME_ADMIN: ["portfolios", "programme_admin"],
    MANAGER: ["portfolios", "manager"],
    ADMINISTRATOR: ["dashboard", "users", "trainees", "unverified-trainees", "supervisors", "assessors", "internal_verifiers", "centre_admins", "centres", "external_verifiers"],
};