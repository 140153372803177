import axios from "axios";
import authHeader from "./auth-header";

import config from "../config";

var API_URL = config.API_URL;

class AssessorService {
  getProfile() {
    return axios.get(API_URL + "assessor", { headers: authHeader() });
  }

  getAssessor(userId) {
    return axios.get(API_URL + "assessor/user/" + userId, { headers: authHeader() });
  }


  getAssessorById(id) {
    return axios.get(API_URL + "assessor/" + id, { headers: authHeader() });
  }

  getAssessmentsForLoggedInAssessor() {
    return axios.get(API_URL + "assessment/assessor", { headers: authHeader() });
  }

  getAssessmentsByAssessorId(id) {
    return axios.get(API_URL + "assessment/assessor/" + id, { headers: authHeader() });
  }


  getAssessorsByCentre(params) {
    return axios.get(API_URL + "assessor/centre" + params, { headers: authHeader() });
  }

  addAssessorsToCentre(emails, centreId) {
    return axios.post(API_URL + "assessor/centre", { emails, centreId }, {
      headers: authHeader(),
    });
  }

  addInvites(emails, centreId) {
    return axios.post(API_URL + "assessor/invite/" + centreId, emails, {
      headers: authHeader(),
    });
  }

  uploadInvites(formData, centreId) {
    return axios.post(API_URL + "assessor/invite/xlsx/" + centreId, formData, {
      headers: authHeader(),
    });
  }

  getInvites(centreId) {
    return axios.get(API_URL + "assessor/invite/" + centreId, {
      headers: authHeader(),
    });
  }

  acceptInvite(token) {
    return axios.put(API_URL + "assessor/invite/" + token, {}, {
      headers: authHeader(),
    });
  }

  getAssessors() {
    return axios.get(API_URL + "assessor/paged", {
      headers: authHeader(),
    });
  }

  getAssessorsByCentreProgramme(id, params) {
    return axios.get(API_URL + `assessor/centre/programme/${id}?${params}`, {
      headers: authHeader(),
    });
  }

  getAssessorsByCentreAndProgramme(centreId, programmeId, params) {
    return axios.get(API_URL + `assessor/centre/${centreId}/programme/${programmeId}?${params}`, {
      headers: authHeader(),
    });
  }

  getDownloadTemplateLink() {
    return API_URL + "assessor/invite/xlsx";
  }

  getRequests(params) {
    return axios.get(API_URL + "assessor/request" + params, {
      headers: authHeader(),
    });
  }

  sendRequest(data) {
    return axios.post(API_URL + "assessor/request", data, {
      headers: authHeader(),
    });
  }

  deleteRequest(id) {
    return axios.delete(API_URL + "assessor/request/" + id, {
      headers: authHeader(),
    });
  }

}

export default new AssessorService();
