import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import VueLogger from "vuejs-logger";


const appInstance = createApp(App);

appInstance.use(router);
appInstance.use(store);
appInstance.use(ArgonDashboard);
// App.use(VueLogger);

appInstance.mount("#app");

