import axios from "axios";
import authHeader from "./auth-header";

import config from "../config";

var API_URL = config.API_URL;

class PortfolioItemService {

  getPortfolioItems(params) {
    return axios.get(API_URL + "portfolio-item/" + params, {
      headers: authHeader(),
    });
  }

  addPortfolioItem(id, portfolioItem, upload) {

    return axios.post(API_URL + `portfolio-item/${id}/${upload ? 'upload/' : ''}`, portfolioItem, {
      headers: authHeader(),
    });
  }

  getDownloadTemplateLink() {
    return API_URL + "portfolio-item/download-template";
  }


  uploadPortfolioItems(formData) {
    return axios.post(API_URL + "portfolio-item/upload", formData, {
      headers: authHeader(),
    });
  }

  uploadPortfolioItemEvidence(portfolioItemId, formData) {
    return axios.post(API_URL + "portfolio-item/" + portfolioItemId + "/evidence", formData, {
      headers: authHeader(),
    })
  }

  deleteEvidenceUrl(id, index) {
    return axios.delete(API_URL + "portfolio-item/" + id + "/evidence/" + index, {
      headers: authHeader(),
    });
  }

  uploadPortfolioItemVideoEvidence(portfolioItemId, formData) {
    return axios.post(API_URL + "portfolio-item/" + portfolioItemId + "/evidence-v", formData, {
      headers: authHeader(),
    })
  }

  deleteVideoEvidenceUrl(id, index) {
    return axios.delete(API_URL + "portfolio-item/" + id + "/evidence-v/" + index, {
      headers: authHeader(),
    });
  }

  saveTracks(id, tracks) {
    return axios.put(API_URL + "portfolio-item/" + id + "/tracks", tracks, {
      headers: authHeader(),
    });
  }

  addRemark(id, remark) {
    return axios.put(API_URL + "portfolio-item/remark/" + id, remark, {
      headers: authHeader(),
    });
  }

  assessItem(id, assessment) {
    return axios.put(API_URL + "portfolio-item/assess/" + id, assessment, {
      headers: authHeader(),
    });
  }

  deletePortfolioItem(id) {
    return axios.delete(API_URL + "portfolio-item/" + id, {
      headers: authHeader(),
    });
  }
}

export default new PortfolioItemService();
