import DashboardLayout from "@/layout/DashboardLayout";
import Portfolio from "../views/trainee/Portfolio.vue";
import Portfolios from "../views/trainee/Portfolios.vue";


export default {
    path: "/trainee/",
    component: DashboardLayout,
    children: [
        {
            path: "portfolio/:id",
            name: "trainee-portfolio",
            components: { default: Portfolio },
        },
        {
            path: "portfolios",
            name: "trainee-portfolios",
            components: { default: Portfolios },
        }
    ]
}