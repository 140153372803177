import axios from "axios";

import config from "../config";
import authHeader from "./auth-header";

var API_URL = config.API_URL;

class AuthService {
  validateLoggedIn() {
    if (localStorage.getItem("auth") == null) {
      return;
    }
    if (authHeader().Authorization == undefined) {
      localStorage.removeItem("auth");
      window.location = window.location.href;
    }
    axios
      .get(API_URL + "user", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.isError) {
          localStorage.removeItem("auth");
          window.location = window.location.href;
        } else {
          var data = JSON.parse(localStorage.getItem("auth"));
          data.user = response.data.result;
          localStorage.setItem("auth", JSON.stringify(data));
        }
      })
      .catch(() => {
        localStorage.removeItem("auth");
        // window.location = `${BASE_ENV_URL}/login`;
      });
  }

  login(user) {
    return axios.post(API_URL + "auth/login", user);
  }

  logout() {
    localStorage.removeItem("auth");
  }

  register({
    firstName,
    lastName,
    email,
    phone,
    password,
    confirmPassword,
    role,
  }) {
    return axios.post(API_URL + "auth/register", {
      firstName,
      lastName,
      email,
      phone,
      password,
      confirmPassword,
      role,
    });
  }

  requestPasswordReset({ phone }) {
    return axios.post(API_URL + "auth/password-reset", { phone });
  }

  verifyPasswordResetToken(token) {
    return axios.get(API_URL + "auth/password-reset/" + token);
  }

  resetPassword({ token, password }) {
    return axios.put(API_URL + "auth/password-reset", { token, password });
  }
}

export default new AuthService();
