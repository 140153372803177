
import DashboardLayout from "@/layout/DashboardLayout";
import DashBoard from "@/views/user/Dashboard";
import Icons from "@/views/user/Icons";
import Account from "@/views/user/Account.vue";
import Profile from "@/views/user/Profile.vue";
import Notifications from "@/views/user/Notifications.vue";

export default {
    path: "/",
    component: DashboardLayout,
    children: [
        {
            path: "/dashboard",
            name: "dashboard",
            components: { default: DashBoard },
        },
        {
            path: "/icons",
            name: "icons",
            components: { default: Icons },
        },
        {
            path: "/account",
            name: "account",
            components: { default: Account },
        },
        {
            path: "/profile",
            name: "profile",
            components: { default: Profile },
        },
        {
            path: "/notifications",
            name: "notifications",
            components: { default: Notifications },
        },
    ],
}