<template>
<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-gradient-white" id="sidenav-main">
    <div class="container-fluid d-block" style="">
        <!--Toggler-->
        <navbar-toggle-button class="float-left" @click="showSidebar">
            <span class="navbar-toggler-icon"></span>
        </navbar-toggle-button>
        <router-link class="navbar-brand d-xs-none" style="" to="/profile">
            <img :src="logo" class="navbar-brand-img" alt="..." />
        </router-link>

        <slot name="mobile-right">
            <ul class="nav align-items-right"></ul>
        </slot>
        <slot></slot>
        <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
            <div class="navbar-collapse-header d-md-none">
                <div class="row">
                    <div class="col-9 collapse-brand">
                        <router-link to="/profile" class="text-default">
                            <img :src="user.photoUrl == null ? '/img/theme/user.jpg' : user.photoUrl" class="rounded-circle"/>
                            <strong class="ml-3 text-bold username">{{ user.firstName }}</strong>
                        </router-link>
                    </div>
                    <div class="col-3 collapse-close">
                        <navbar-toggle-button @click="closeSidebar"></navbar-toggle-button>
                    </div>
                </div>
            </div>

            <ul class="navbar-nav">
                <slot name="links"> </slot>
            </ul>
            <!--Divider-->
            <hr class="my-3" />
            <!--Heading-->
            <h6 class="navbar-heading text-muted">Support</h6>
            <!--Navigation-->
            <ul class="navbar-nav mb-md-3">
                <li class="nav-item">
                    <a class="nav-link" href="mailto:support@e-limi.africa">
                        <i class="ni ni-email-83"></i> Send an Email
                    </a>
                    <a class="nav-link" href="tel:08139710056" title="Click to call 08139710056">
                        <i class="ni ni-world"></i> Call Support
                    </a>
                </li>
            </ul>
            <hr class="my-3" />
            <!--Heading-->
            <!--Navigation-->
            <ul class="navbar-nav mb-md-3">
                <li class="nav-item">
                    <a class="nav-link" href="#" @click="logout">
                        <i class="ni ni-button-power"></i> Logout
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
</template>

<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";

export default {
    name: "sidebar",
    components: {
        NavbarToggleButton,
    },
    data() {
        return {
            user: {},
        }
    },
    props: {
        autoClose: {
            type: Boolean,
            default: true,
            description: "Whether sidebar should autoclose on mobile when clicking an item",
        },
    },
    provide() {
        return {
            autoClose: this.autoClose,
        };
    },
    computed: {
        logo() {
            return this.user.photoUrl || '/img/theme/user.jpg';
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("auth/logout");
            this.$router.push({path: '/'})
        },
        closeSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        showSidebar() {
            this.$sidebar.displaySidebar(true);
        },
    },
    beforeUnmount() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem("auth")).user;
    },
};
</script>

<style scoped>
.user-img-overlay{
  opacity: 0;
}
.navbar-brand-img {
  opacity: 1;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border: solid 1px orangered;
  box-shadow: 0px 3px 10px rgb(220, 200, 250) !important;
}

hr {

  box-shadow: 0px 3px 10px rgb(250, 240, 230) !important;
}

.username {
    font-size: 18px;
    font-weight: 600;
}
.navbar-brand {
    text-align: center !important;
}
@media (max-width: 767px){
    .navbar-brand {
        float: right !important;
    }
}
</style>