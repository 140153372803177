<template>
  <div
    class="main-content bg-"
    :style="`background: url(${require('../assets/image/bg2.jpg')}) no-repeat;background-size: cover;`"
  >
    <!-- <div class="box right">
      <div class="wave -one"></div>
      <div class="wave -two"></div>
      <div class="wave -three"></div>
    </div> -->
    <!-- Navbar -->
    <base-nav
      class="navbar-top navbar-horizontal navbar-bg navbar-light"
      containerClasses="px-4 container"
      expand
    >
      <template v-slot="{ closeMenu }">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img src="img/brand/green.png" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                @click="closeMenu"
                class="navbar-toggler text-default"
                aria-label="Toggle sidenav"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a
              class="nav-link nav-link-icon"
              target="_blank"
              href="https://e-limi.africa/"
            >
              <!-- <i class="fa fa-home"></i> -->
              <span class="nav-link-inner--text">Home</span>
            </a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/register">
              <!-- <i class="ni ni-circle-08"></i> -->
              <span class="nav-link-inner--text">Register</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/login">
              <!-- <i class="ni ni-key-25"></i> -->
              <span class="nav-link-inner--text">Login</span>
            </router-link>
          </li>
          <li class="nav-item">
            <a
              class="nav-link nav-link-icon"
              target="_blank"
              href="https://e-limi.africa/home/contact-page/"
            >
              <!-- <i class="ni ni-support-16"></i> -->
              <span class="nav-link-inner--text">Support</span>
            </a>
          </li>
        </ul>
      </template>
    </base-nav>
    <!-- Header -->
    <div class="header py-7 py-lg-8">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <h1 class="text-white"></h1>
              <p class="text-lead text-white"></p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div> -->
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <router-view></router-view>
    </div>
    <footer class="footer px-4 mt-5">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6">
          <div class="copyright text-center text-lg-left text-dark">
            © {{ year }}
            <a
              href="https://e-limi.africa"
              class="font-weight-bold ml-1 text-dark"
              target="_blank"
              >C-STEMP Educational Technology</a
            >
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                href="https://e-limi.africa/home/about/"
                class="nav-link"
                target="_blank"
              >
                About
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://www.e-limi.africa/home/accreditation/"
                class="nav-link"
                target="_blank"
                >Accreditation</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://e-limi.africa/home/publications/"
                class="nav-link"
                target="_blank"
                >Publications</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://e-limi.africa/home/contact-page/"
                class="nav-link"
                target="_blank"
                >Contact Us</a
              >
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
};
</script>

<style lang="scss">
.navbar-bg {
  background: linear-gradient(
    -165deg,
    rgba(225, 225, 225, 0.9) 0%,
    rgba(225, 225, 225, 0.4) 40%,
    rgba(225, 225, 225, 0) 70%,
    rgba(225, 225, 225, 0) 87%
  );
}

.nav-link i {
  color: #77132b;
}

.nav-link:hover i {
  color: #f7941d;
}

.active > .nav-link-inner--text {
  font-weight: 600 !important;
  color: #9d2642;
}

.nav-link-inner--text {
  font-weight: 600 !important;
  color: #77132b;
}

.box {
  position: fixed;
  transform: rotate(80deg);

  &.right {
    transform: rotate(-250deg);
    top: 30px;
    width: 20%;
    height: 20vh;
    right: 10%;
  }
}

.wave {
  position: absolute;
  opacity: 0.4;
  width: 1700px;
  height: 1900px;
  border-radius: 43%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}

.wave.-one {
  animation: rotate 70000ms infinite linear;
  opacity: 0.9;
  background: linear-gradient(#ffffffae, rgba(255, 238, 0, 0.587), #ffffff89);
}

.wave.-two {
  animation: rotate 30000ms infinite linear;
  opacity: 0.4;
  left: 200vh;
  background: linear-gradient(#ffffff, #f7951d, #ffffff);
}

.wave.-three {
  animation: rotate 75000ms infinite linear;
  opacity: 0.9;
  background-color: linear-gradient(
    #77132aae,
    rgba(255, 255, 255, 0.599),
    #77132a89
  );
}
</style>
