<template>
<div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar short-title="NSQs | E-LIMI">
        <template v-slot:links>
            <sidebar-item :link="{
            name: user.role == 'ADMINISTRATOR' ? 'Dashboard' : 'Profile',
            icon: 'ni text-default ' + (user.role == 'ADMINISTRATOR' ? 'ni-chart-bar-32' : 'ni-single-02'),
            path: '/profile',
          }" />
          
            <sidebar-item v-if="user.roles.includes('TRAINEE')" :link="{
            name: 'Portfolios',
            icon: 'fa fa-list text-default',
            path: '/trainee/portfolios',
          }" />
            <!-- END TRAINEE -->

            <!-- START ASSESSOR -->
            <sidebar-item v-if="user.roles.includes('ASSESSOR')" :link="{
            name: 'Requests',
            icon: 'fa fa-share text-default',
            path: '/assessor/requests',
          }" />
            <!-- <sidebar-item v-if="user.roles.includes('ASSESSOR')" :link="{
            name: 'Invitations',
            icon: 'ni ni-paper-diploma text-default',
            path: '/assessor/invitations',
          }" /> -->
            <sidebar-item v-if="user.roles.includes('ASSESSOR')" :link="{
            name: 'Portfolios',
            icon: 'fa fa-list text-default',
            path: '/assessor/portfolios',
          }" />
            <sidebar-item v-if="user.roles.includes('ASSESSOR')" :link="{
            name: 'Assessments',
            icon: 'ni ni-collection text-default',
            path: '/assessor/assessments',
          }" />
            <!-- END ASSESSOR -->

            <!-- START INTERNAL_VERIFIER -->
            <!-- <sidebar-item v-if="user.roles.includes('INTERNAL_VERIFIER')" :link="{
            name: 'Invitations',
            icon: 'ni ni-paper-diploma text-default',
            path: '/internal-verifier/invitations',
          }" /> -->
            <sidebar-item v-if="user.roles.includes('INTERNAL_VERIFIER')" :link="{
            name: 'Requests',
            icon: 'fa fa-share text-default',
            path: '/internal-verifier/requests',
          }" />
            <sidebar-item v-if="user.roles.includes('INTERNAL_VERIFIER')" :link="{
            name: 'Portfolios',
            icon: 'fa fa-list text-default',
            path: '/internal-verifier/portfolios',
          }" />
            <sidebar-item v-if="user.roles.includes('INTERNAL_VERIFIER')" :link="{
            name: 'Assessors',
            icon: 'ni ni-badge text-default',
            path: '/internal-verifier/assessors',
          }" />
            <sidebar-item v-if="user.roles.includes('INTERNAL_VERIFIER')" :link="{
            name: 'Verifications',
            icon: 'ni ni-collection text-default',
            path: '/internal-verifier/verifications',
          }" />
            <!-- END INTERNAL_VERIFIER -->

            <!-- START CENTRE_ADMIN -->
            <sidebar-item v-if="user.roles.includes('CENTRE_ADMIN')" :link="{
            name: 'Dashboard',
            icon: 'fa fa-chart-bar text-default',
            path: '/centre/dashboard',
          }" />
            <sidebar-item v-if="user.roles.includes('CENTRE_ADMIN')" :link="{
            name: 'Centre',
            icon: 'fa fa-home text-default',
            path: '/centre/administrator',
          }" />
            <sidebar-item v-if="user.roles.includes('CENTRE_ADMIN')" :link="{
            name: 'Portfolios',
            icon: 'fa fa-list text-default',
            path: '/centre/portfolios',
          }" />
            <sidebar-item v-if="user.roles.includes('CENTRE_ADMIN')" :link="{
            name: 'Assessors',
            icon: 'ni ni-badge text-default',
            path: '/centre/assessors',
          }" />
            <sidebar-item v-if="user.roles.includes('CENTRE_ADMIN')" :link="{
            name: 'Verifiers',
            icon: 'fa fa-check text-default',
            path: '/centre/internal-verifiers',
          }" />
            <!-- END CENTRE_ADMIN -->

            <!-- START EXTERNAL_VERIFIER -->
            <sidebar-item v-if="user.roles.includes('EXTERNAL_VERIFIER')" :link="{
            name: 'Dashboard',
            icon: 'fa fa-chart-bar text-default',
            path: '/external-verifier/dashboard',
          }" />
            <sidebar-item v-if="user.roles.includes('EXTERNAL_VERIFIER')" :link="{
            name: 'Centres',
            icon: 'fa fa-home text-default',
            path: '/external-verfifier/centres',
          }" />
            <!-- <sidebar-item v-if="user.roles.includes('EXTERNAL_VERIFIER')" :link="{
            name: 'Programmes',
            icon: 'fa fa-table text-default',
            path: '/external-verifier/programmes',
          }" /> -->
            <!-- END EXTERNAL_VERIFIER -->

            <!-- START PROGRAMME_ADMIN -->
            <sidebar-item v-if="user.roles.includes('PROGRAMME_ADMIN')" :link="{
            name: 'Dashboard',
            icon: 'fa fa-chart-bar text-default',
            path: '/centre/dashboard',
          }" />
            <!-- END PROGRAMME_ADMIN -->

            <!-- START ADMIN -->
            <sidebar-item v-if="user.roles.includes('ADMINISTRATOR')" :link="{
            name: 'Users',
            icon: 'fa fa-users text-default',
            path: '/users',
          }" />
            <sidebar-item v-if="user.roles.includes('ADMINISTRATOR')" :link="{
            name: 'Unverified Trainees',
            icon: 'fa fa-hard-hat text-default',
            path: '/trainees/unverified',
          }" />
            <sidebar-item v-if="user.roles.includes('ADMINISTRATOR')" :link="{
            name: 'Assessors',
            icon: 'ni fa fa-edit text-default',
            path: '/assessors',
          }" />
            <sidebar-item v-if="user.roles.includes('ADMINISTRATOR')" :link="{
            name: 'Centres',
            icon: 'ni fa fa-building text-default',
            path: '/centres',
          }" />
            <!-- END ADMIN -->

            <sidebar-item :link="{
            name: 'Account',
            icon: 'ni ni-briefcase-24 text-default',
            path: '/account',
          }" />


        </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
        <dashboard-navbar></dashboard-navbar>

        <div @click="toggleSidebar">
            <!-- your content here -->
            <router-view></router-view>
            <content-footer v-if="!$route.meta.hideFooter"></content-footer>
        </div>
    </div>
</div>
</template>

<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import pageRoles from "../router/page-roles";

export default {
    components: {
        DashboardNavbar,
        ContentFooter,
    },
    data() {
        return {
            pages: pageRoles,
            sidebarBackground: "red", //vue|blue|orange|green|red|primary
        };
    },
    computed: {
        user() {
            return JSON.parse(localStorage.getItem("auth")).user;
        },
      isInAssessmentSquad() {
        return this.user.roles.includes('ASSESSOR') || this.user.roles.includes('INTERNAL_VERIFIER')
          || this.user.roles.includes('EXTERNAL_VERIFIER');
        }

        //notificationsTxtColor() {
        //    return this.user.notifications.length == 0 ? 'text-grey' : ''
        //}
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
    },
};
</script>

<style lang="scss"></style>
