import axios from "axios";
import authHeader from "./auth-header";

import config from "../config";

var API_URL = config.API_URL;

class ProgrammeService {
  getProgrammes() {
    return axios.get(API_URL + "programme", { headers: authHeader() });
  }

  getProgrammeById(programmeId) {
    return axios.get(API_URL + "programme/" + programmeId, { headers: authHeader() });
  }

  getProgramme() {
    return axios.get(API_URL + "programme/admin", { headers: authHeader() });
  }

  getAssessorProgrammes() {
    return axios.get(API_URL + "programme/assessor", { headers: authHeader() });
  }

  getInternalVerifierProgrammes() {
    return axios.get(API_URL + "programme/internal-verifier", { headers: authHeader() });
  }

  getExternalVerifierProgrammes() {
    return axios.get(API_URL + "programme/external-verifier", { headers: authHeader() });
  }

  getCentreProgrammes() {
    return axios.get(API_URL + "programme/centre", { headers: authHeader() });
  }

  acceptProgramme(programmeId) {
    return axios.post(API_URL + "programmes/accept/" + programmeId, {
      headers: authHeader(),
    });
  }

  declineProgramme(programmeId) {
    return axios.post(API_URL + "programmes/decline/" + programmeId, {
      headers: authHeader(),
    });
  }

}

export default new ProgrammeService();
