import axios from "axios";
import authHeader from "./auth-header";

import config from "../config";

var API_URL = config.API_URL;

class ProfileService {
  getProfile() {
    return axios.get(API_URL + "profile", { headers: authHeader() });
  }

  getProfileById(userId) {
    return axios.get(API_URL + "profile/user/" + userId, { headers: authHeader() });
  }

  getProfiles(params) {
    return axios.get(API_URL + "profile/filtered/" + params, { headers: authHeader() });
  }

  getDownloadTemplateLink() {
    return API_URL + "profile/download-template";
  }

  saveProfile(profile) {
    return axios.put(API_URL + "profile", profile, {
      headers: authHeader(),
    });
  }

  // Education
  addAcademicDegree(degree) {
    return axios.post(API_URL + "profile/education", degree, {
      headers: authHeader(),
    });
  }

  updateAcademicDegree(index, degree) {
    return axios.put(API_URL + "profile/education/" + index, degree, {
      headers: authHeader(),
    });
  }

  uploadDegreeCertificate(index, formData) {
    return axios.patch(API_URL + "profile/education/" + index, formData, {
      headers: authHeader(),
    });
  }

  deleteAcademicDegree(index) {
    return axios.delete(API_URL + "profile/education/" + index, {
      headers: authHeader(),
    });
  }

  // Experience
  addJob(job) {
    return axios.post(API_URL + "profile/experience", job, {
      headers: authHeader(),
    });
  }

  updateJob(index, job) {
    return axios.put(API_URL + "profile/experience/" + index, job, {
      headers: authHeader(),
    });
  }

  uploadJobEvidence(index, formData) {
    return axios.put(API_URL + "profile/experience/" + index, formData, {
      headers: authHeader(),
    });
  }

  deleteJob(index) {
    return axios.delete(API_URL + "profile/experience/" + index, {
      headers: authHeader(),
    });
  }

  // Certificationss
  addCertifications(certifications) {
    return axios.post(API_URL + "profile/certifications", certifications, {
      headers: authHeader(),
    });
  }

  updateCertifications(index, certifications) {
    return axios.put(API_URL + "profile/certifications/" + index, certifications, {
      headers: authHeader(),
    });
  }

  deleteCertifications(index) {
    return axios.delete(API_URL + "profile/certifications/" + index, {
      headers: authHeader(),
    });
  }

}

export default new ProfileService();
