import { createRouter, createWebHistory } from "vue-router";

import PageRoles from "./page-roles";

/**
 * 
 * Public Vues
 * 
 */
import Auth from "./auth";

/**
 * 
 * Protected Vues
 * 
 */
import User from "./user";

/**
 * 
 * Role Based Vues
 * 
 */
import Trainee from "./trainee";
import Assessor from "./assessor";
import InternalVerifier from "./internal-verifier";
import CentreAdmin from "./centre-admin";
import ExternalVerifier from "./external-verifier";
import ProgrammeAdmin from "./programme-admin";
import Administrator from "./administrator";

const routes = [
  Auth,
  User,
  Trainee,
  Assessor,
  InternalVerifier,
  CentreAdmin,
  ExternalVerifier,
  ProgrammeAdmin,
  Administrator,
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  const authRequired = !PageRoles.PUBLIC.includes(to.name);
  var loggedIn = localStorage.getItem("auth");

  // trying to access a restricted page without being logged in will redirect to login page
  if (authRequired) {
    if (!loggedIn) {

      if (!to.fullPath.includes("login")) {
        localStorage.setItem("to", JSON.stringify(to));
      }

      next("/login");

    }

    loggedIn = JSON.parse(loggedIn);

    if (loggedIn.tokenType != "Bearer") {
      next("/login");
    }

    var entryPoint = localStorage.getItem("to");

    // console.log(to);

    localStorage.removeItem("to");

    if (entryPoint != null) {

      entryPoint = JSON.parse(entryPoint);

      if (PageRoles.PUBLIC.includes(entryPoint.name)) {
        return next("/profile");
      } else {
        return next(entryPoint.fullPath);
      }

    }

    var isIncluded = false;

    for (const role of loggedIn.user.roles) {

      if (PageRoles[role].includes(to.name)) {

        isIncluded = true;

        break;
      }
    }

    if (!isIncluded && !PageRoles.PROTECTED.includes(to.name)) {
      return next("/profile");
    }
  }
  return next();
});

export default router;
