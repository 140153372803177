<template>
<div>
    <div readonly class="mb-3 text-white text-bold btn-block pl-3 pr-2 py-3"  :class="`btn-outline-${type}`" :style="background" v-if="message != '' && message != null" @click="message = ''; $emit('update:message', message);" style="font-size: 0.775rem;border-radius:5px;">
        <i class="fa fa-times float-right text-dark bg-white" style="height:17px;width:17px;border-radius:100%;padding:2.5px 0 0 4px;">
        </i>
        <span v-html="message"></span>
    </div>
</div>
</template>

<script>
export default {
    name: "form-message",
    props: {
        message: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "info",
        },
    },
    emits: ['update:message'],
    computed: {
        background() {
            switch (this.type) {
                case "info":
                    return "background: linear-gradient(70deg, #3858bf, #3858af) !important;";
                case "success":
                    return "background: linear-gradient(70deg, #0ABF98, #0ACFA8) !important;";
                case "warning":
                    return "";
                case "danger":
                    return "background: linear-gradient(70deg, #bf5858, #af5458) !important;";
                case "primary":
                    return "";
                case "light":
                    return "";
                case "dark":
                    return "";
                case "default":
                default:
                    return "#fff";
            }
        },
        text_color() {
            switch (this.type) {
                case "info":
                    return "";
                case "success":
                    return "";
                case "warning":
                    return "";
                case "danger":
                    return "";
                case "primary":
                    return "";
                case "light":
                    return "";
                case "dark":
                    return "";
                case "default":
                default:
                    return "#fff";
            }
        }
    }
}
</script>
