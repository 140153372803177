import axios from "axios";
import authHeader from "./auth-header";

import config from "../config";

var API_URL = config.API_URL;

class CentreService {
  getCentres() {
    return axios.get(API_URL + "centre", { headers: authHeader() });
  }

  getCentres(params) {
    return axios.get(API_URL + "centre/filtered/" + params, {
      headers: authHeader(),
    });
  }

  getCentre() {
    return axios.get(API_URL + "centre/admin", {
      headers: authHeader(),
    });
  }

  addCentre(centre) {
    return axios.post(API_URL + "centre", centre, {
      headers: authHeader(),
    });
  }

  getCentresByProgramme(programmeId) {
    return axios.get(API_URL + "centre/programme/" + programmeId, {
      headers: authHeader(),
    });
  }

  updateCentre(centre) {
    return axios.put(API_URL + "centre", centre, {
      headers: authHeader(),
    });
  }

  getDownloadTemplateLink() {
    return API_URL + "centre/download-template";
  }

  getCentresByAssessors() {
    return axios.get(API_URL + "centre/assessor", {
      headers: authHeader(),
    });
  }

  requestToAssessForCentre(data) {
    return axios.post(API_URL + "centre/assessor", data, {
      headers: authHeader(),
    });
  }

  acceptRequestFromAssessor(formData) {
    return axios.put(API_URL + "centre/upload", formData, {
      headers: authHeader(),
    });
  }

  rejectRequestFromAssessor(data) {
    return axios.delete(API_URL + "centre/assessor", data, {
      headers: authHeader(),
    });
  }

  uploadCentres(formData) {
    return axios.post(API_URL + "centre/upload", formData, {
      headers: authHeader(),
    });
  }

  uploadCentres(formData) {
    return axios.post(API_URL + "centre/upload", formData, {
      headers: authHeader(),
    });
  }

  deleteCentre(id) {
    return axios.delete(API_URL + "centre/" + id, {
      headers: authHeader(),
    });
  }
}

export default new CentreService();
