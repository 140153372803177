import DashboardLayout from "@/layout/DashboardLayout";
import Portfolio from "../views/internal_verifier/Portfolio.vue";
import Portfolios from "../views/internal_verifier/Portfolios.vue";
import Assessor from "../views/internal_verifier/Assessor.vue";
import Assessors from "../views/internal_verifier/Assessors.vue";
import Invitation from "../views/internal_verifier/Invitation.vue";
import Requests from "../views/internal_verifier/Requests.vue";
import Invitations from "../views/internal_verifier/Invitations.vue";
import Centres from "../views/internal_verifier/Centres.vue";
import Verifications from "../views/internal_verifier/Verifications.vue";


export default {
    path: "/internal-verifier/",
    component: DashboardLayout,
    children: [
        {
            path: "invite/accept/:token",
            name: "internal-verifier-invitation",
            components: { default: Invitation },
        },
        {
            path: "portfolio/:id",
            name: "internal-verifier-portfolio",
            components: { default: Portfolio },
        },
        {
            path: "portfolios",
            name: "internal-verifier-portfolios",
            components: { default: Portfolios },
        },
        {
            path: "assessor/:id",
            name: "internal-verifier-assessor",
            components: { default: Assessor },
        },
        {
            path: "assessors",
            name: "internal-verifier-assessors",
            components: { default: Assessors },
        },
        {
            path: "requests",
            name: "internal-verifier-requests",
            components: { default: Requests },
        },
        {
            path: "invitations",
            name: "internal-verifier-invitations",
            components: { default: Invitations },
        },
        {
            path: "centres",
            name: "internal-verifier-centres",
            components: { default: Centres },
        },
        {
            path: "Verifications",
            name: "internal-verifier-verifications",
            components: { default: Verifications },
        },
    ]
}