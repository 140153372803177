import axios from "axios";
import authHeader from "./auth-header";

import config from "../config";

var API_URL = config.API_URL;

class ExternalVerifierService {

  getProfile() {
    return axios.get(API_URL + "external-verifier", { headers: authHeader() });
  }

  getExternalVerifier(userId) {
    return axios.get(API_URL + "external-verifier/user/" + userId, { headers: authHeader() });
  }

  getExternalVerifiersByCentre(params) {
    return axios.get(API_URL + "external-verifier/centre" + params, { headers: authHeader() });
  }

  getExternalVerifiersByCentreProgramme(id, params) {
    return axios.get(API_URL + `external-verifier/centre/programme/${id}?${params}`, {
      headers: authHeader(),
    });
  }

  addInvites(emails, centreId) {
    return axios.post(API_URL + "external-verifier/invite/" + centreId, emails, {
      headers: authHeader(),
    });
  }

  uploadInvites(formData, centreId) {
    return axios.post(API_URL + "external-verifier/invite/xlsx/" + centreId, formData, {
      headers: authHeader(),
    });
  }

  getInvites(centreId) {
    return axios.get(API_URL + "external-verifier/invite/" + centreId, {
      headers: authHeader(),
    });
  }

  acceptInvite(token) {
    return axios.put(API_URL + "external-verifier/invite/" + token, {}, {
      headers: authHeader(),
    });
  }

  getRequests(params) {
    return axios.get(API_URL + "external-verifier/request" + params, {
      headers: authHeader(),
    });
  }

  sendRequest(data) {
    return axios.post(API_URL + "external-verifier/request", data, {
      headers: authHeader(),
    });
  }

  deleteRequest(id) {
    return axios.delete(API_URL + "external-verifier/request/" + id, {
      headers: authHeader(),
    });
  }

}
export default new ExternalVerifierService();
