import axios from "axios";
import authHeader from "./auth-header";

import config from "../config";

var API_URL = config.API_URL;

class PortfolioService {
  getPortfolios() {
    return axios.get(API_URL + "portfolio", { headers: authHeader() });
  }

  getPortfolioById(id) {
    return axios.get(API_URL + "portfolio/id/" + id, { headers: authHeader() });
  }

  searchPortfolios(params) {
    return axios.get(API_URL + "portfolio/" + params, {
      headers: authHeader(),
    });
  }

  getPortfoliosByTrainee(traineeId) {
    return axios.get(API_URL + "portfolio/trainee/" + traineeId, {
      headers: authHeader(),
    });
  }

  getPortfoliosByAssessor(assessorId) {
    return axios.get(API_URL + "portfolio/assessor/" + assessorId, {
      headers: authHeader(),
    });
  }

  getPortfoliosByAssessorProgramme(programmeId, params) {
    return axios.get(API_URL + "portfolio/assessor/programme/" + programmeId + params, {
      headers: authHeader(),
    });
  }

  getPortfoliosByAssessorAndCentre(assessorId, centreId, params) {
    return axios.get(API_URL + "portfolio/assessor/" + assessorId + '/centre/' + centreId + params, {
      headers: authHeader(),
    });
  }

  verifyPortfolio(id, verification) {
    return axios.put(API_URL + "portfolio/verification/" + id, verification, {
      headers: authHeader(),
    });
  }

  getAssessmentsByAssessorProgramme(programmeId, params) {
    return axios.get(API_URL + "portfolio/assessment/assessor/programme/" + programmeId + params, {
      headers: authHeader(),
    });
  }

  getPortfoliosByInternalVerifier(ivId) {
    return axios.get(API_URL + "portfolio/internal-verifier/" + ivId, {
      headers: authHeader(),
    });
  }

  getPortfoliosByInternalVerifierProgramme(programmeId, params) {
    return axios.get(API_URL + "portfolio/internal-verifier/programme/" + programmeId + params, {
      headers: authHeader(),
    });
  }

  getPortfoliosByExternalVerifierProgramme(programmeId, params) {
    return axios.get(API_URL + "portfolio/external-verifier/programme/" + programmeId + params, {
      headers: authHeader(),
    });
  }

  getPortfoliosByCentre(centre) {
    return axios.get(API_URL + "portfolio/centre/" + assessorId, {
      headers: authHeader(),
    });
  }

  getPortfoliosByCentreProgramme(programmeId, params) {
    return axios.get(API_URL + "portfolio/centre/programme/" + programmeId + params, {
      headers: authHeader(),
    });
  }

  getAssessmentsByCentreProgramme(programmeId, params) {
    return axios.get(API_URL + "portfolio/assessment/centre/programme/" + programmeId + params, {
      headers: authHeader(),
    });
  }

  getPortfoliosByExternalVerifier(evId) {
    return axios.get(API_URL + "portfolio/external-verifier/" + evId, {
      headers: authHeader(),
    });
  }

  addLogSheet(logSheet) {
    return axios.post(API_URL + "portfolio", logSheet, {
      headers: authHeader(),
    });
  }

  getDownloadTemplateLink() {
    return API_URL + "portfolio/download-template";
  }

  addPortfolio(portfolio) {
    return axios.post(API_URL + "portfolio", portfolio, {
      headers: authHeader(),
    });
  }

  uploadPortfolios(formData) {
    return axios.post(API_URL + "portfolio/upload", formData, {
      headers: authHeader(),
    });
  }

  deleteLogSheet(id) {
    return axios.delete(API_URL + "portfolio/" + id, {
      headers: authHeader(),
    });
  }
}

export default new PortfolioService();
