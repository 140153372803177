<template>
    <div>

    </div>
</template>
<script>

import AssessorService from "@/api/services/assessor.service";
import BaseButton from '@/components/BaseButton.vue';

export default {
    name: "assessor-invitation",
    components: {
        BaseButton,
    },
    data() {
        return {
            model: {},
            

        };
    },
    created() {
        AssessorService.acceptInvite(this.$route.params.token).then((response) => {
            this.$router.push({ name: "assessor-invitation" });
        }).catch((error) => {
            window.location.reload();
        });
    },
};
</script>