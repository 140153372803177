import AuthLayout from "@/layout/AuthLayout";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import RegisterByRole from "../views/auth/RegisterByRole.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";


export default {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
        {
            path: "/forgot-password",
            name: "forgot-password",
            components: { default: ForgotPassword },
        },
        {
            path: "/password-reset/:token",
            name: "password-reset",
            components: { default: ResetPassword },
        },
        {
            path: "/login",
            name: "login",
            components: { default: Login },
        },
        {
            path: "/register",
            name: "register",
            components: { default: Register },
        },
        {
            path: "/register/:role",
            name: "register-by-role",
            components: { default: RegisterByRole },
        },
    ],
}